// react imports
import React, { useContext, useState, useEffect } from "react";
import { Outlet, useSearchParams, useNavigate } from "react-router-dom";

import AppContext from '../../AppContext';
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import sidenavButton from '../../assets/media/icons/menu.png'
import cpeptide_icon from '../../assets/media/icons/cpeptide.png';
import user from '../../assets/media/icons/user-green.png';
import user_white from '../../assets/media/icons/user.png';
import privacy from '../../static/jogi/privacy.pdf'
// MDBOOTSTRAP IMPORTS
import { MDBContainer,  
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBSideNavCollapse,
  MDBIcon,
  MDBBtn,
  MDBRow,
  MDBInput,
  MDBCol } from "mdb-react-ui-kit";

// IMPORT LAYOUT COMPONENTS
import Footer from "./footer/Footer";
import SignUpModal from "../modals/SignUpModal";
import ProfileEditModal from "../modals/ProfileEditModal";
import SignInModal from "../modals/SignInModal";
import PasswordresetModal from "../modals/PasswordresetModal";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";
import PrivateEmailModal from "../modals/PrivateEmailModal";
import EmailVerifiedModal from "../modals/EmailVerifiedModal";
import CookiesModal from "../modals/CookiesModal";
import ProfileDeleteModal from "../modals/ProfileDeleteModal";
import HirlevelModal from "../modals/HirlevelModal";
import SocialRegistrationModal from "../modals/SocialRegistrationModal";
import Cookies from 'universal-cookie';
const cookies = new Cookies();




function Layout({ children }) {
  const context = useContext(AppContext);
  
  
  // STATES
  const [basicOpen, setBasicOpen] = useState(false);
  const [basicCollapse1, setBasicCollapse1] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [cookiesIsAccepted, setCookiesIsAccepted] = useState(getCookieConsentValue());

  // react router navigation
  const navigate = useNavigate();


  // events
  const loginEvent = (email, password) => {
    context.setIsLoading(true)
    context.interAxios.post('/auth/login', {password: password, email: email}, function(response) {
      context.onSignIn(response.data);
      context.setIsLoading(false);
      context.closeLoginModal();
      if(context.getState()?.isToVideoPage){
        context.onSetActiveItem("2");
        navigate('/videos')
      }
    }, function(error) {
      alert("incorrect email or password");
    });

  };

  const showLogoutClicked = () => {
    context.onLogout();
  }

  const scrollEventListener = () => {

        if(window.scrollY < 50){
          setIsScrolling(false);
        }else {
          setIsScrolling(true);
        }
   
  }

  const handleWindowSizeChange = () => {
    
  };

  useEffect(() => {
    document.addEventListener("scroll", scrollEventListener);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        document.removeEventListener("scroll", scrollEventListener);
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);

  
 
  useEffect(() => {
    if(window.location.pathname.split("/")[window.location.pathname.split("/").length-1] === "change-password"){
      context.openLoginModal("resetpassword")
    }  
   
  }, []);

  useEffect(() => {
    setCookiesIsAccepted(getCookieConsentValue());
    context.onRenderFalse()
  }, [context.getState()?.isRender]);
  
  const onSearchText  = (event) => {
    event.preventDefault();
    setSearchText(event.target.value);
  };


  return (
    <>
    <MDBContainer style={{maxWidth: "1920px"}} className='p-0' id="project-container">
    
    {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videoplay" && 
        window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videodetail" &&(
      <>
     
        <MDBNavbar expand='lg' sticky light bgColor='light' className={!isScrolling ? "navbar-bottom d-none d-lg-block" : "navbar-bottom iscrolling d-none d-lg-block"}>
          <MDBContainer fluid className="d-flex justify-content-start align-items-center" style={{height: "100%"}}>
          <MDBNavbarBrand>
                
              <img
                  src={cpeptide_icon}
                  width='162'
                  alt=''
                  loading='lazy'
              />
          </MDBNavbarBrand>
          <div className="navitem-padding">
            <MDBNavbarItem className='home' onClick={() => {
                              context.onSetActiveItem("1");
                              navigate('/home');
                              window.scrollTo({ top: 0, behavior: 'smooth' })}}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "1"} >HOME</MDBNavbarLink>
            </MDBNavbarItem>
          
              <MDBNavbarItem className='videos' onClick={() => {
                              if(context.isLoggedIn()){
                                navigate("/videos"),
                                context.onSetActiveItem("2");
                            }else{
                                context.openLoginModal("signin")
                                context.onToVideosPage();
                            }
                              }}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "2"} >Videók</MDBNavbarLink>
            </MDBNavbarItem>
            {context.isLoggedIn() && (
              <MDBNavbarItem className='news' onClick={() => {
                              context.onSetActiveItem("3");
                              navigate('/news');
                              
                                }}>
                <MDBNavbarLink  active={context.getState()?.activeItem === "3"} >Hírek</MDBNavbarLink>
              </MDBNavbarItem>
            )}
            
            <MDBNavbarItem className='faq' onClick={() => {
                              context.onSetActiveItem("4");
                              navigate('/home');
                              setTimeout(() => {var element = document.getElementById('faq');
                                var headerOffset = 110;
                                var elementPosition = element.getBoundingClientRect()?.top;
                                var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                              
                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: "smooth"
                                })}, 500);
                              }}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "4"} >Gyakori kérdések</MDBNavbarLink>
            </MDBNavbarItem>
            
            <MDBNavbarItem className='contact' onClick={() => {
                              context.onSetActiveItem("5");
                              navigate('/kapcsolatok');
                              
                              }}>
              <MDBNavbarLink  active={context.getState()?.activeItem === "5"} >Kapcsolat</MDBNavbarLink>
            </MDBNavbarItem>
           
            
          </div>
          {context.isLoggedIn() && (
            <div className="d-flex justify-content-end align-items-center" style={{width: "100%"}}>
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                  <img src={user} alt="user" height='19'/>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem link onClick={() => context.openLoginModal("editprofile")}>Fiókom</MDBDropdownItem>
                  <MDBDropdownItem link onClick={() => {context.onLogout(); navigate("/home"); context.onSetActiveItem("1")}}>Kilépés</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              </MDBNavbarItem>
            </div>
          )}
        
          </MDBContainer>
         
          
        </MDBNavbar>
     
      
       
      <div className=" d-block d-lg-none navbar-side" >
      <div className="sidenav-bottom-respo">
      
        <MDBRow className="m-0 p-0" style={{width: "100%", height: "100%"}}>
          <MDBCol md="3" sm="3" size="3" className="d-flex justify-content-start align-items-center ps-4">
            <MDBBtn onClick={() => setBasicOpen(!basicOpen)} className="sidenavButton">
              <img src={sidenavButton} />
            </MDBBtn>
          </MDBCol>
          <MDBCol md="9" sm="9" size="9" className="d-flex align-items-center justify-content-start  pe-3">
            <div className="d-flex justify-content-end align-items-center title-text"  style={{width: "100%"}}>
              <img
                  src={cpeptide_icon}
                  width='162'
                  alt=''
              />
            </div>
          </MDBCol>
        </MDBRow>
    
        </div>
        <MDBSideNav isOpen={basicOpen} mode='side' absolute left getOpenState={(e) => setBasicOpen(e)}>
        <MDBSideNavMenu>
        <MDBRow className="m-0 pb-5" style={{width: "100%", height: "100%"}}>
          <MDBCol  className="d-flex justify-content-start align-items-center ps-4 pb-5">
            <MDBBtn onClick={() => setBasicOpen(!basicOpen)} className="sidenavButton-2">
              <img src={sidenavButton} />
            </MDBBtn>
          </MDBCol>
        </MDBRow>
          <MDBSideNavItem onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' });  setBasicOpen(!basicOpen); context.onSetActiveItem("1"); navigate('/home')}}>
            <MDBSideNavLink active={context.getState()?.activeItem === "1"}>
                Home
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem  onClick={() => {
                              if(context.isLoggedIn()){
                                navigate("/videos");
                                context.onSetActiveItem("2");
                                setBasicOpen(!basicOpen);
                            }else{
                                setBasicOpen(!basicOpen);
                                context.openLoginModal("signin")
                                context.onToVideosPage();
                            }
                              }}>
            <MDBSideNavLink active={context.getState()?.activeItem === "2"}>
              Videók
            </MDBSideNavLink>
          </MDBSideNavItem>
          {context.isLoggedIn() && (
            <MDBSideNavItem className='news' onClick={() => {
                            context.onSetActiveItem("3");
                            setBasicOpen(!basicOpen);
                            navigate('/news');
                            
                            }}>
            <MDBSideNavLink  active={context.getState()?.activeItem === "3"} >Hírek</MDBSideNavLink>
          </MDBSideNavItem>
          )}
          
          <MDBSideNavItem  onClick={() => {
                            context.onSetActiveItem("4");
                            setBasicOpen(!basicOpen);
                            navigate("/home");
                            setTimeout(() => {var element = document.getElementById('faq');
                              var headerOffset = 100;
                              var elementPosition = element.getBoundingClientRect()?.top;
                              var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                            
                              window.scrollTo({
                                  top: offsetPosition,
                                  behavior: "smooth"
                              })}, 500);
                            }}>
            <MDBSideNavLink active={context.getState()?.activeItem === "4"} >
              Gyakori kérdések
            </MDBSideNavLink>
          </MDBSideNavItem>
          <MDBSideNavItem className='contact' onClick={() => {
                              context.onSetActiveItem("5");
                              setBasicOpen(!basicOpen);
                              navigate('/kapcsolatok');
                              
                              }}>
              <MDBSideNavLink  active={context.getState()?.activeItem === "5"} >Kapcsolat</MDBSideNavLink>
            </MDBSideNavItem>
          {context.isLoggedIn() && (
          <MDBSideNavItem className='user-item'>
            <MDBSideNavLink icon='angle-down' shouldBeExpanded={basicCollapse1} onClick={() => {setBasicCollapse1(!basicCollapse1)}}>
            <img src={user_white} alt="user" height='19'/>
            
            </MDBSideNavLink>
            <MDBSideNavCollapse open={basicCollapse1} show={basicCollapse1} >
              <MDBSideNavLink  onClick={() => {context.openLoginModal("editprofile"); navigate("/home"); context.onSetActiveItem("1");  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setBasicCollapse1(!basicCollapse1); setBasicOpen(!basicOpen) }}> My Profile</MDBSideNavLink>
              <MDBSideNavLink onClick={() => {context.onLogout(); navigate("/home"); context.onSetActiveItem("1");  window.scrollTo({ top: 0, behavior: 'smooth' })}}> Logout</MDBSideNavLink>
            </MDBSideNavCollapse>
          </MDBSideNavItem>)}
        </MDBSideNavMenu>
        </MDBSideNav>
      </div>
      </>
    )}
      <main>
      <Outlet/>
      </main>
    {window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videoplay" && 
    window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videodetail" &&
    window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "news" && (
      <Footer />
    )}
      
      {context.getState()?.modalName === "signup" && (
        <SignUpModal
        visible={context.getState()?.loginModalOpened}
        hideModal={() => {context.closeLoginModal(); context.onSetActiveItem("1");}}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
        context={context}
      />
      )}
       {context.getState()?.modalName === "socialsignup" && (
        <SocialRegistrationModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        accessToken={context.getState()?.accessToken}
        authorizationCode={context.getState()?.authorizationCode}
        socialType={context.getState()?.socialType}
        context={context}
        lastName={context.getState()?.lastName}
        firstName={context.getState()?.firstName}
      />
      )}
      {context.getState()?.modalName === "editprofile" && (
        <ProfileEditModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
      />
      )}
      {context.getState()?.modalName === "deleteProfile" && (
        <ProfileDeleteModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "signin" && (
        <SignInModal
        visible={context.getState()?.loginModalOpened}
        hideModal={() => {context.closeLoginModal(); context.onSetActiveItem("1");}}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
      />
      )}
      {context.getState()?.modalName === "resetpassword" && (
        <PasswordresetModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "forgotpassword" && (
        < ForgotPasswordModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        loginEvent={loginEvent}
      />
      )}
      {context.getState()?.modalName === "hirlevel" && (
        <HirlevelModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
      {context.getState()?.modalName === "cookiesmodal" && (
        <CookiesModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
       {context.getState()?.modalName2 === "privateemailmodal" && (
        <PrivateEmailModal
        visible={context.getState()?.loginModalOpened2}
        hideModal={context.closeLoginModal2}
        openLoginModal={context.openLoginModal2}
      />
      )}
        {context.getState()?.modalName === "emailverified" && (
        <EmailVerifiedModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
      />
      )}
   
    </MDBContainer>
    {!cookiesIsAccepted && (
      <CookieConsent
          location="bottom"
          buttonWrapperClasses="cookie-button-classes"
          contentClasses="cookie-content"
          buttonText="Elfogadom"
          expires={31}
          style={{ background: "#f9f9fa", color: "#252626", borderTop: "2px solid #EBEAEF", display: "flex", alignItems: "flex-start" }}
          buttonClasses="accept"
          onAccept={context.onRenderTrue}
          enableDeclineButton
          overlay
          declineButtonText="Nem fogadom el"
          declineButtonClasses="reject"
          onDecline={context.onRenderTrue}
        >
            <div className="d-flex justify-content-between align-items-start">
              <div>
                Ez a weboldal a felhasználói élmény fokozása, a felhasználó kényelme, a weboldal megfelelő működése, továbbá statisztikai és reklámozási célok érdekében sütiket (cookiekat) használ. Részletesebb tájékoztatást az 
                <a href={privacy} target="_blank"> adatvédelmi tájékoztatóban</a> olvashat. <a className=""  onClick={() => context.openLoginModal("cookiesmodal") } style={{cursor: "pointer"}}> További lehetőségek</a>
              </div>
            </div>
         
      </CookieConsent>
    )}
    
    </>
  );
}

export default Layout;
