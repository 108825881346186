import React, { useContext } from "react";

import AppContext from "../../AppContext";
import SignInForm from "../form-components/SignInForm";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
} from "mdb-react-ui-kit";

const SignInModal = (props) => {
  const context = useContext(AppContext);


  return (
    <MDBModal
      staticBackdrop
      tabIndex="-1"
      closeOnEsc={false}
      show={props.visible}
      className="signin-modal"
    >
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={props.hideModal}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBModalTitle>
              <span className="modal-title">Jelentkezz be a tartalom eléréséhez!</span>
            </MDBModalTitle>
            <SignInForm
              hideModal={props.hideModal}
              loginEvent={props.loginEvent}
            />
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};
export default SignInModal;
