// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"
import Cookies from 'universal-cookie';
import aszf from '../../static/jogi/aszf.pdf'
import privacy from '../../static/jogi/privacy.pdf'

const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBIcon,
  MDBInputGroup,
  MDBCheckbox
} from 'mdb-react-ui-kit';




// STYLE IMPORTS
import "react-toggle/style.css";



class ProfileForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    confirmPassword: "",
                    directMarketingConsent: this.props.checkedMarketing
      };
  };
/*
  componentDidMount(){
    document.getElementById("controlledCheckbox_1").setCustomValidity("Kérjük fogadd el a nyilatkozatot, kattints a körbe!");
    document.getElementById("controlledCheckbox_2").setCustomValidity("Kérjük fogadd el a nyilatkozatot, kattints a körbe!");
  }
*/
    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const token = this.props.captchaRef.current.getValue();

      if(this.state.password === this.state.confirmPassword){
        if(this.state.email.match(validRegex)){
          this.props.context.interAxios.post('/auth/register',
          {
            email: this.state.email,
            password: this.state.password,
            lastName: this.state.lastName,
            firstName: this.state.firstName,
            directMarketingConsent: this.props.checkedMarketing,
            captchaToken: token,
            
          }, function(response) {
              self.props.context.closeLoginModal()
              self.props.context.openLoginModal("succesRegistration");
          
                 
            }, function (error) {
              alert('Sikertelen regisztráció! Ellenörizze a megadott adatokat!')
          });
     
        }else {
          alert('Helytelen email formátum!')
        }
      }else {
        alert('Ellenörizze a megadott jelszavakat!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="profile-form" autoComplete="off">
                <div className="white-panel">
                  {/** FIRST NAME **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="lastName">Vezetéknév*</label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.firstnameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='6'>
                      <label htmlFor="firstName">Keresztnév*</label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        ref={this.props.firstnameRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  {/** EMAIL **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="email">E-mail cím*</label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordfirstRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                  {/** PASSWORDs **/}
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='6'>
                      <label htmlFor="password">Jelszó*</label>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordfirstRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.passwordtwoRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                    <MDBCol md='6'>
                      <label htmlFor="confirmPassword">Jelszó mégegyszer*</label>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePasswordtwo ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePasswordtwo(!this.props.hidePasswordtwo)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePasswordtwo ? 'password' : 'text'} 
                          value={this.state.confirmPassword}
                          onChange={this.changeHandler}
                          ref={this.props.passwordtwoRef}
                          id='confirmPassword'
                          name='confirmPassword'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.nameRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>

                <MDBRow className="py-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <MDBRow className="pb-2 m-0">
                        <MDBCol md='12'  className="d-grid align-items-center justify-content-start ">
                           <div className="checkbox-div d-flex align-items-start pb-3" style={{fontSize: "13px"}}>
                            <MDBCheckbox
                              id='controlledCheckbox_1'
                              label=""
                              checked={this.props.checked}
                              setCustomValidity="message"

                              onChange={() => this.props.setChecked(!this.props.checked)}
                              required
                            /> 
                            <div>
                            Kijelentem, hogy a Proinsulin-cpeptide  <a href={aszf} target="_blank">általános szerződési feltételeket </a>teljes körűen megismertem és 
                            elfogadom, az abban foglalt rendelkezéseket magamra nézve kötelezőnek ismerem el.
                            </div>
                            
                          </div>   
                          <div className="checkbox-div d-flex align-items-start pb-3" style={{fontSize: "13px"}}>
                            <MDBCheckbox
                              id='controlledCheckbox_2'
                              label=""
                              checked={this.props.checked_2}
                              onChange={() => this.props.setChecked_2(!this.props.checked_2)}
                              required
                            /> 
                            <div>
                            Kijelentem, hogy az <a href={privacy} target="_blank">adatkezelési tájékoztatójának </a>tartalmát teljes körűen megismertem és az adatkezelési 
                            tájékoztató tartalmát tudomásul vettem, továbbá hozzájárulok ahhoz, hogy a személyes adataimat az adatkezelési tájékoztatóban meghatározottak szerinti céllal és feltételekkel kezelje a Proinsuli-cpeptide oldal. 
                            </div>
                            
                          </div>   
                          <div className="checkbox-div d-flex align-items-start pb-0" style={{fontSize: "13px"}}>
                            <MDBCheckbox
                              id='controlledCheckbox_3'
                              label=""
                              checked={this.props.checkedMarketing}
                              onChange={() => this.props.setCheckedMarketing(!this.props.checkedMarketing)}
                            /> 
                            <div>
                            Önkéntesen hozzájárulok, hogy e-mail címemet a Proinsulin-cpeptide oldal üzemeltetöi visszavonásig direkt marketing célra, hírlevél küldése érdekében kezelje az 
                            általam megismert adatkezelési tájékoztatónak megfelelően, és részemre ajánlatokat küldjön, aktuális ajánlatairól tájékoztasson. A hozzájárulás a jövőre nézve bármikor visszavonható a   
                            <a href={"mailto:info@proinsulin-cpeptide.com"} target="_blank"> info@proinsulin-cpeptide.com </a>e-mail cím alatt.
                            </div>
                            
                          </div>     
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                 
                          </MDBRow>

                
       
                  <MDBRow className="py-0 m-0">
                    <MDBCol md='12' className="p-0 d-flex justify-content-center align-items-center ">
                      <ReCAPTCHA
                        ref={this.props.captchaRef}
                        sitekey={"6LdW2m8pAAAAALVoCR60oqp8wAMx1WsO6j_LocZM"}
                      />
                    </MDBCol>
                  </MDBRow>
               
                 
                 <MDBRow center className="pb-0 m-0">
                    <MDBCol md='12' className="p-0">
                          <div className="d-flex justify-content-center align-items-center pt-3">
                            <button type="submit" className="rergistration-button modal-button green" ref={this.props.buttonRef}>
                              <span>Regisztrálok</span>
                            </button>
                          </div>
                    </MDBCol>
                  
                  </MDBRow>
                  </div>
              </form>

               
      </>
    );
  }
};

function ProfileFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checked_2, setChecked_2] = useState(false);
  const [checkedMarketing, setCheckedMarketing] = useState(false);
  const navigate = useNavigate();
  const firstnameRef = useRef();
  const emailRef = useRef();
  const passwordfirstRef = useRef();
  const passwordtwoRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const buttonRef = useRef();
  const buttonRef_2 = useRef();
  const captchaRef = useRef()
  return <ProfileForm searchParams={searchParams} 
                      navigate={navigate} 
                      hideModal={props.hideModal} 
                      context={props.context} 
                      firstnameRef={firstnameRef}
                      emailRef={emailRef}
                      passwordfirstRef={passwordfirstRef}
                      passwordtwoRef={passwordtwoRef}
                      nameRef={nameRef}
                      addressRef={addressRef}
                      postcodeRef={postcodeRef}
                      cityRef={cityRef}
                      countryRef={countryRef}
                      buttonRef={buttonRef}
                      buttonRef_2={buttonRef_2}
                      hidePassword={hidePassword}
                      setHidePassword={setHidePassword}
                      hidePasswordtwo={hidePasswordtwo}
                      setHidePasswordtwo={setHidePasswordtwo}
                      checked={checked}
                      checked_2={checked_2}
                      checkedMarketing={checkedMarketing}
                      setChecked={setChecked}
                      setChecked_2={setChecked_2}
                      setCheckedMarketing={setCheckedMarketing}
                      captchaRef={captchaRef}
                      />;
}

export default ProfileFormFunc;
