// react imports
import React, { useState , useRef} from "react";

// MDBOOTSTRAP IMPORTS
import { MDBInputGroup, MDBIcon } from "mdb-react-ui-kit";


/**
 * Props:
 * @param value: string
 * @param onChange: (e) => {}
 * @param onBlur?: (e) => {}
 * @param inputClass?: string
 * @param placeholder?: string
 * @param id?: string
 * @param name?: string
 * @param required?: boolean
 * @returns 
 */
const PasswordInput = (props) => {
  // STATES
  const [hidePassword, setHidePassword] = useState(true);

  const passwordRef = useRef();
  return (
    <>
      <MDBInputGroup
        className="jc-password"
        noWrap 
        textAfter={(
          <MDBIcon 
            className={"fa-regular" + (hidePassword ? ' fa-eye' : ' fa-eye-slash')}
            onClick={() => setHidePassword(!hidePassword)}
          ></MDBIcon>
        )}
      >
        <input 
          type={hidePassword ? 'password' : 'text'} 
          value={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
          ref={passwordRef}
          placeholder={props.placeholder} 
          id={props.id}
          name={props.name}
          required={props.required}
        />
      </MDBInputGroup>
    </>
  );
};

export default PasswordInput;