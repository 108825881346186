// react imports
import React, { useContext, Component } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import backgroundImage from '../../assets/media/images/bg.png'
// CUSTOM COMPONENTS
import AppContext from '../../AppContext';

import { MDBRow, 
         MDBCol,
         MDBContainer
          } from 'mdb-react-ui-kit';

class KapcsolatokPage extends Component {


  constructor(props) {
    super(props);
      
      this.state = {email: "",
                    nev: "",
                    text: ""
      };
    };

 
componentDidMount() {
  this.props.context.onSetActiveItem("5");
  
  setTimeout(() => {
    //this.openLoginModal("firstwarning")}, 500)
    window.scrollTo(0, 0)}, 500)
  
}





    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        let self = this;
        this.props.context.interAxios.post('/contact', {
                                                name: this.state.nev,
                                                email: this.state.email,
                                                message: this.state.text
                                               }, 
        function(response) {
          self.props.context.openLoginModal("uzenet")
          self.setState({
            email: "",
            nev: "",
            text: ""
          })
        });
        
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
 
  
render() {
  let imageHeight =  document.getElementById("backgroundImage")?.height;
  return (
    <div ref={this.listInnerRefTop}>
      
       <div className="kapcsolatok" id="kapcsolatok">
          <img src={backgroundImage} id="backgroundImage" className={"backgroundImage-style"}/>
          <MDBContainer style={{maxWidth: "660px"}} >
            
            <form onSubmit={(e) => this.onCustomer(e)} className="kapcsolatok-form" autoComplete="off">
              <div className="kapcsolatok-title d-flex align-items-center justify-content-center">
                <MDBRow style={{maxWidth: "660px"}} className="m-0 pb-5">
                    <span>Küldje el üzenetét!</span>
                </MDBRow>
              </div>  
                <div className="white-panel" >
                  {/** EMAIL **/}
                  <MDBRow className="pb-4 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="nev">Név</label><br/>
                      <input
                        id='nev'
                        name='nev'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.nev}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-4 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="password">Email</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="pb-4 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="text">Üzenet</label><br/>
                      <textarea 
                        id='text'
                        name='text'
                        type='textarea'
                        onChange={this.changeHandler}
                        value={this.state.text}
                        rows="4" 
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-center" >
                          <div>
                            <button type="submit" className="rergistration-button modal-button green" >
                            <span>Küldés</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>

                  <div className="kapcsolatok-footer pt-3">
                    <MDBRow center style={{maxWidth: "660px"}} className="m-0 pb-2">
                        <p>További információk:</p>
                    </MDBRow>
                    <MDBRow style={{maxWidth: "660px"}} className="m-0">
                        <p className="normal-text m-0"><span className="bold-text">E-mail: </span><a style={{color: "#314A2A", textDecorationLine: "underline"}} href={"mailto:info@proinsulin-cpeptide.com"}> info@proinsulin-cpeptide.com</a></p>
                    </MDBRow>
                
                  </div>  

                </div>
            </form>
          </MDBContainer>
        </div>
       
    </div>


  );
}
 
};

function KapcsolatokPageFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  return <KapcsolatokPage searchParams={searchParams} navigate={navigate}  context={context}/>;
}

export default KapcsolatokPageFunc;