import React, { Component, useContext  } from "react";
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../../../AppContext';
import Moment from 'moment';

import TableTemplate from './TableTemplateUser.js';
import trash from '../../../../../assets/media/icons/Trash-red.svg';

import { MDBBtn,
         MDBRow,
         MDBSwitch
       } from 'mdb-react-ui-kit';

class ClientTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clientList: [],
      searchText: undefined,
      pageIndex: 0,
      pageSize: 10,
      numberOfPages: 1,
      totalElements: 10,}
    }

  componentDidMount() {
    this.onGetAllClient(this.state.searchText, this.state.pageIndex, this.state.pageSize);
  }

  componentDidUpdate(prewProps, prewState) {

  }


  onGetAllClient = (searchText, pageIndex, pageSize) => {
    let self = this;
    this.props.context.interAxios.get('/admin/client',
    {
              params: {q: searchText,
                       pageIndex: pageIndex,
                       pageSize: pageSize < 10 ? 10 : pageSize
                       }},
    function(response) {
      self.setState({clientList: response.data.content,
                     pageSize: response.data.pageSize !== 0 ? response.data.pageSize : 1,
                     pageIndex: response.data.pageIndex,
                     numberOfPages: response.data.numberOfPages,
                     totalElements: response.data.totalElements})
    });
  }

  onChangePageIndex = (pageIndex) => {
    this.onGetAllClient(this.state.searchText, pageIndex, this.state.pageSize);
  }
  onChangePageSize = (pageSize, pageIndex) => {
    this.onGetAllClient(this.state.searchText, pageIndex, pageSize);
  }

  onSearchtext = (searchText) => {
    this.setState({searchText: searchText})
    this.onGetAllClient(searchText, 0, this.state.pageSize);
  }

  onDeleteClient = (id) => {
    let self = this;
    this.props.context.interAxios.delete('/admin/client/' + id, function(response) {
      self.onGetAllClient();
      
    });
  }

  handleSwitchChange = (id) => {
    let self = this;
    this.props.context.interAxios.patch('/admin/client/' + id, 
    { "directMarketingConsent": false },
     function(response) {
      self.onGetAllClient();
    })

  };


  render(){
      let table = this.state.clientList?.map((k,n) => {
        return(
          <tr key={n}>
          <td>
            <MDBRow className="d-flex align-items-center justify-content-start m-0">
              {k.lastName}
            </MDBRow>
          </td>
          <td>
            <MDBRow className="d-flex align-items-center justify-content-start m-0">
              {k.firstName}
            </MDBRow>
          </td>
          <td>
            <MDBRow className="d-flex align-items-center justify-content-start m-0">
              {k.email}
            </MDBRow>
          </td>
          
          <td>
            <MDBRow className="d-flex align-items-center justify-content-center m-0">
              {k.directMarketingConsent}
            <div className="d-flex align-items-center justify-content-center">
              {k.directMarketingConsent && (
                <MDBSwitch key={n} id="cmarketingfilter" className="form-row" checked={k.directMarketingConsent} onChange={() => this.handleSwitchChange(k.id)}/>
              )}
              <span >{k.directMarketingConsent ? "Aktív" : "Inaktív"}</span>
            </div>
            </MDBRow>
          </td>
          <td className="text-center">
            <MDBRow center className="d-flex align-items-center justify-content-center">
              {Moment(k.createdAt).format('YYYY.MMM.DD')}
            </MDBRow>
          </td>
         
         
          <td>
            <MDBRow center>
              <MDBBtn type="btn" className="table-icon-btn" onClick={() => this.onDeleteClient(k.id)}><img src={trash} style={{width: "32px", height: "32px", borderRadius: "50%"}}/></MDBBtn>
            </MDBRow>
          </td>
          </tr>
        )
      })

    return(
     <TableTemplate {...this} {...this.state} {...this.props} searchText={this.state.searchText}>
       {table}
     </TableTemplate>
    );
  }

}
export default function ClientTableFunc(props) {
    const context = useContext(AppContext);
    let location = useLocation();
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    return <ClientTable context={context}
                        searchParams={searchParams}
                        navigate={navigate}
                        location={location}/>;
}
