import React, { useContext, useState  } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { backendUrl } from '../../env';
import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBCheckbox
} from 'mdb-react-ui-kit';


const SocialRegistrationModal = (props) => {

const context = useContext(AppContext);

const [checked, setChecked] = useState(false);
const [checked_2, setChecked_2] = useState(false);
const [checked_3, setChecked_3] = useState(false);
const [checkedMarketing, setCheckedMarketing] = useState(false);

// router hooks
const navigate = useNavigate();

    const onCustomer = (e) => {
      e.preventDefault();
      props.hideModal()
      if(props.socialType === "facebook"){
        context.interAxios.post('/auth/facebook/register', {token: props.accessToken, directMarketingConsent: checkedMarketing}, function(response) {
          context.onSignIn(response.data);
          props.hideModal()
        });
      }
      if(props.socialType === "apple"){
        axios.post(backendUrl() +'/auth/apple/register', { 'token': props.accessToken, 'authorizationCode': props.authorizationCode, directMarketingConsent: checkedMarketing, lastName: props.lastName, firstName: props.firstName}, { headers: { 'Content-type': 'application/json', 'Accept': 'application/json', 'Platform-Id': '3'}}).then(function(response) {
          context.onSignIn(response.data);
          props.hideModal()
        })
      }
      if(props.socialType === "google"){
        context.interAxios.post('/auth/google/register', {token: props.accessToken, directMarketingConsent: checkedMarketing}, function(response) {
          context.onSignIn(response.data);
          props.hideModal()
        });
      }
     
      
    }


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="signin-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalHeader>
              
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">A regisztrációhoz szükséges a következőket elfogadni:</span>
          </MDBModalTitle>
          <form onSubmit={(e) => onCustomer(e)} className="hirlevel-form" autoComplete="off">
            <MDBRow className="pt-2 pb-4 m-0">
                  <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                    <div className="checkbox-div d-flex align-items-start pb-1">
                      <MDBCheckbox
                        id='controlledCheckbox_1'
                        label=""
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        required
                      /> 
                      <span className="modal-description">Elolvastam és elfogadom a 
                        <a href={'/aszf'} target="_blank" rel="noopener noreferrer"> felhasználási feltételeket</a>.<span className="stern">*</span></span>
                      
                    </div>   
                  </MDBCol>
                  <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                    <div className="checkbox-div d-flex align-items-start pb-1">
                      <MDBCheckbox
                        id='controlledCheckbox_2'
                        label=""
                        checked={checked_2}
                        onChange={() => setChecked_2(!checked_2)}
                        required
                      /> 
                      <span className="modal-description">Elolvastam és elfogadom az 
                        <a href={'/aszf'} target="_blank" rel="noopener noreferrer"> adatvédelmi tájékoztatót</a>.<span className="stern">*</span></span>
                      
                    </div>   
                  </MDBCol>
                  <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0 pt-2">
                    <div className="checkbox-div d-flex align-items-start pb-1">
                      <MDBCheckbox
                        id='controlledCheckbox_3'
                        label=""
                        checked={checked_3}
                        onChange={() => setChecked_3(!checked_3)}
                        required
                      /> 
                      <span className="modal-description">Hozzájárulok, hogy az általam megadott szeményes adatokat az Adatkezelő az Adatkezelési tájékoztatóban meghatározott célból kezelje.<span className="stern">*</span></span>
                      
                    </div>   
                  </MDBCol>
                  <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0 pt-2">
                    <div className="checkbox-div d-flex align-items-start pb-1">
                      <MDBCheckbox
                        id='controlledCheckbox_4'
                        label=""
                        checked={checkedMarketing}
                        onChange={() => setCheckedMarketing(!checkedMarketing)}
                      /> 
                      <span className="modal-description">Önkéntesen hozzájárulok, hogy e-mail címemet a Proinsuli-cpeptide oldal visszavonásig direkt marketing célra, hírlevél 
                      küldése érdekében kezelje az általam megismert adatkezelési tájékoztatónak megfelelően, 
                      és részemre ajánlatokat küldjön, aktuális ajánlatairól tájékoztasson. 
                      A hozzájárulás a jövőre nézve bármikor visszavonható a info@proinsulin-cpeptide.com e-mail cím alatt.</span>
                      
                    </div>   
                  </MDBCol>
                </MDBRow>
              <div className="justify-content-start d-flex align-ites-center pt-3" >
                <button type="submit" className="rergistration-button modal-button black" >
                  <span>TOVÁBB</span>
                </button>
              </div>
            </form>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default SocialRegistrationModal;
