import React, { Component, useContext, useEffect } from 'react';
import AppContext from '../../../AppContext';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../../form-components/PasswordInput';
import {
  MDBContainer,
  MDBRow,
  MDBCol
}
from 'mdb-react-ui-kit';

class AdminLoginPage extends Component {
    state = {
  		password: '',
      username: '',
    };

    constructor(props) {
    	super(props);
  	}

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    onLoginFormSubmit  = (e) => {
      e.preventDefault();
      let self = this;
      this.props.context.interAxios.post('/auth/login', {password: this.state.password, email: this.state.email}, function(response) {
        //self.props.onSignIn(response.data);
        //cookies.set('authtoken', {token: response.data.authToken}, { path: '/'});
        self.props.context.onSignIn(response.data);
        self.props.navigate('/admin');
      });
      //this.props.context.onSignIn("Boda71", "12345678");
    }

    render() {
      return(
        <div className="signin-modal  pt-5">
          <span className="modal-title">Admin bejelentkezés</span>
        <MDBContainer style={{maxWidth: "600px"}}>
        <form onSubmit={(e) => this.onLoginFormSubmit(e)} className="signin-form" autoComplete="off">
                <div className="white-panel">
                  {/** EMAIL **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="firstName">Felhasználó név / E-mail cím</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="password">Jelszó</label><br/>
                      <PasswordInput
                        id='password'
                        name='password'
                        type='password'
                        onChange={this.changeHandler}
                        value={this.state.password}
                        required
                      />
                    </MDBCol>
                  </MDBRow>


                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-center" >
                          <div>
                            <button type="submit" className="rergistration-button modal-button green" >
                            <span>Belépés</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </form>
        </MDBContainer>
        </div>
      )

    }
}

export default function AdminLoginPageFunc(props) {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
      if (context.isLoggedIn()) {
        navigate('/admin');
      }
    });
    return <AdminLoginPage context={context} navigate={navigate}/>;
}
