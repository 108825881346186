// react imports
import React, { useContext, useState, useEffect } from "react";

// CUSTOM COMPONENTS
import AppContext from '../../AppContext';

import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';



const Gyik = () => {


  // REACT ROUTER PARAMS - for processing url
  const context = useContext(AppContext);

  // STATES
  const [faqList, setFaqList] = useState([])

  // EVENTS
 useEffect(() => {
   context.interAxios.get('/faq', function(response) {
        setFaqList(response.data)
    });
  }, []);


  return (
    <div className="gyik" >
      {faqList.length > 0 && (
         <MDBAccordion flush initialActive={1}>
         {faqList.map((k,n) => {return(
           <MDBAccordionItem key={n} collapseId={n+1} headerTitle={<div dangerouslySetInnerHTML={{__html: k.question}} style={{width: "100%"}}></div>}>
             <div className="gyik-text" dangerouslySetInnerHTML={{__html: k.answer}} style={{fontSize: window.innerWidth > 1920 ? 24 : (window.innerWidth/1920)*24 +8}}></div>
           </MDBAccordionItem>
 
         )})}
 
         </MDBAccordion>
      )}  
    </div>


  );
};

export default Gyik;
