// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate, Link } from "react-router-dom";
import prev from '../../assets/media/icons/prev-green.png'

// import Swiper and modules styles
import 'swiper/css';
import AppContext from '../../AppContext';
import {  MDBContainer,
          MDBSideNav,
          MDBSideNavMenu,
          MDBSideNavItem,
          MDBSideNavLink,
          MDBSideNavCollapse,
          MDBBtn,
          MDBIcon
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const NewsPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [numberOfPages, setNumberOfPages] = useState(1);
  
  const [newsList, setNewsList] = useState([]);
  const [newsById, setNewsById] = useState({});
  const [activeLink, setActiveLink] = useState(1);

  const [basicOpen_2, setBasicOpen_2] = useState(true);


  const handleWindowSizeChange = () => {
    
 
  };
  
  useEffect(() => {
    onGetAllNews(pageIndex, pageSize)
    setTimeout(() => {setBasicOpen_2(true) }, 300)
  }, []);

  useEffect(() => {
    context.onSetActiveItem("3");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const onGetAllNews = (pageIndex, pageSize) => {
    let array = newsList;
    context.interAxios.get('/news',
    {
              params: {pageIndex: pageIndex,
                       pageSize: pageSize < 15 ? 15 : pageSize
                       }},
    function(response) {
      console.log(response.data)
      setNumberOfPages(response.data.numberOfPages)
      let newArray = newsList.concat(response.data.content)
      setNewsList(newArray)
      setNewsById(response.data.content[0])
      if(pageIndex < response.data.numberOfPages){
        setPageIndex(pageIndex + 1)
      }
    });
  }

  const onGetNewsById = (id) => {
    setNewsById(newsList.filter((k) => k.id === id)[0])
  }

  const truncateString = (str, num) => {
    
    if (str !== undefined && str.length <= num) {
      
        return str
      }
          return str.slice(0, num) + '...'
  }
 
  return (
    <MDBContainer fluid className="news-container">
      <section className="news-section d-none d-lg-flex">
       <div className="news-list-div">
        {newsList.map((k,n) => {return (
          <div className={activeLink === n+1 ? "news-card active" : "news-card"} onClick={() => {setActiveLink(n+1); onGetNewsById(k.id)}} key={n}>
            <div className="title-text">
              <span  dangerouslySetInnerHTML={{__html: truncateString(k.title, 180)}}/>
            </div>
            
            <div className="content">
              {k.excerpt !== undefined && (
                <div className="content-text" dangerouslySetInnerHTML={{__html: k.excerpt}}/>
              )}
             
            </div>
            <div className="date">
              <span>{k.date}</span>
            </div>
          </div>
        )})}
        {pageIndex < numberOfPages && (
          <div className="more-button">
            <button onClick={() => {onGetAllNews(pageIndex, pageSize)}}>
              <span>Mégtöbb hír </span><MDBIcon fas icon="angle-double-right" />
            </button>
          </div>
        )}
       
       </div>
       <div className="news-content-div">
          <div className="date-text">
            <span>{newsById.date}</span>
          </div>
          <div className="news-title">
            <span>{newsById.title}</span>
          </div>
          <div className="news-content">
            <div className="content-text" dangerouslySetInnerHTML={{__html: newsById.content}}/>
          </div>
       </div>
      </section>
      <section className="news-section-respo d-flex d-lg-none">
        <MDBSideNav isOpen={basicOpen_2} mode='side' backdrop={false} absolute left  getOpenState={(e) => setBasicOpen_2(e)}>
          <div className="news-list-div">
            {newsList.map((k,n) => {return (
              <div className={activeLink === n+1 ? "news-card active" : "news-card"} onClick={() => {setActiveLink(n+1); onGetNewsById(k.id); setBasicOpen_2(!basicOpen_2)}} key={n}>
                <div className="title-text">
                <span dangerouslySetInnerHTML={{__html: truncateString(k.title, 90)}}/>
                </div>
                
                <div className="content">
                  {k.excerpt !== undefined && (
                    <div className="content-text" dangerouslySetInnerHTML={{__html: k.excerpt}}/>
                  )}
                
                </div>
                <div className="date">
                  <span>{k.date}</span>
                </div>
              </div>
            )})}
            {pageIndex < numberOfPages && (
              <div className="more-button">
                <button onClick={() => {onGetAllNews(pageIndex, pageSize)}}>
                  <span>Mégtöbb hír </span><MDBIcon fas icon="angle-double-right" />
                </button>
              </div>
            )}
          </div>
        </MDBSideNav>

        <div className='news-content-div'>
          <button onClick={() => setBasicOpen_2(!basicOpen_2)}>
            <MDBIcon fas icon="angle-double-left" /><span> Vissza</span>
          </button>
          <div className="date-text">
            <span>{newsById.date}</span>
          </div>
          <div className="news-title">
            <span>{newsById.title}</span>
          </div>
          <div className="news-content">
            <div className="content-text" dangerouslySetInnerHTML={{__html: newsById.content}}/>
          </div>
        </div>
      </section>
    </MDBContainer>


  );
};

export default NewsPage;
