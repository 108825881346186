export function envDefaultString(fromEnv, defaultValue) {
    return fromEnv === undefined ? defaultValue : fromEnv;
}

export function backendUrl() {
    // stage
    //return "https://proinsulin-cpeptide.com:8751/api/v1"
    return envDefaultString(process.env.REACT_APP_BACKEND_URL, "https://proinsulin-cpeptide.com:8751/api/v1");
}

export function ownStyleCSSUrl() {
    return backendUrl() + "/style.css";
}