import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
// LAYOUT, PAGE IMPORTS
import Layout from './components/layout/Layout';
import VideosPage from './components/pages/VideosPage';
import LandingPage from './components/pages/LandingPage';
import KapcsolatokPage from './components/pages/KapcsolatokPage';
import AdminLoginPage from './components/pages/admin/AdminLoginPage';
import AdminLandingPage from './components/pages/admin/AdminLandingPage';
import FaqPage from './components/pages/admin/FaqPage';
import ClientPage from './components/pages/admin/ClientPage';
import LiveCalendarPage from './components/pages/admin/CalendarComponent/LiveCalendarPage';
import VideosDetailPage from './components/pages/VideosDetailPage';
import VideoPlayPage from './components/pages/VideoPlayPage';
import CategoryDetailPage from './components/pages/CategoryDetailPage';
import NewsPage from './components/pages/NewsPage';
import SearchPage from './components/pages/SearchPage';
import { GoogleOAuthId } from './data/loginkeys';

function App() {
  return (
    <GoogleOAuthProvider clientId={GoogleOAuthId.clientId}>
      <AppContextProvider>
        <BrowserRouter>
        
            <Routes>
              <Route path="/" element={<Layout/>}>
                <Route path="" element={<LandingPage />} />
                <Route path="/home" element={<LandingPage />} />
                <Route path="/profile/change-password" element={<LandingPage />} />
                <Route path="/kapcsolatok" element={<KapcsolatokPage />} />
                <Route path="/videos" element={<VideosPage />} />
                <Route path="/videoplay" element={<VideoPlayPage />} />
                <Route path="/videodetail" element={<VideosDetailPage />} />
                <Route path="/categorydetail" element={<CategoryDetailPage />} />
                <Route path="/news" element={<NewsPage />} />
                <Route path="/searchpage" element={<SearchPage />} />
              </Route>
                <Route path="/admin" element={<AdminLandingPage/>}>
                <Route path="faq/*" element={<FaqPage/>}/>
                <Route path="client/*" element={<ClientPage/>}/>
                <Route path="livecalendar/*" element={<LiveCalendarPage/>}/>
              </Route>

    <Route path="admin/login" element={<AdminLoginPage/>}/>
            </Routes>
        </BrowserRouter>
      </AppContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
