import React, { useContext, useState } from "react";
import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBSwitch
} from 'mdb-react-ui-kit';

import Cookies from 'universal-cookie';
const cookies = new Cookies();


const CookiesModal = (props) => {
const context = useContext(AppContext);

// STATES
const [szuksegesSuti, setSzuksegesSuti] = useState(cookies.get('CookieConsent')?.szuksegesSuti)
const [statisztikaSuti, setStatisztikaSuti] = useState(cookies.get('CookieConsent')?.statisztikaSuti)
const [reklamSuti, setReklamSuti] = useState(cookies.get('CookieConsent')?.reklamSuti)

    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="cookies-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalHeader>
            
            <MDBBtn className='btn-close' color='none' onClick={props.hideModal}></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">Cookie-beállítások</span></MDBModalTitle>
              <span className="modal-description">A cookie-kat kategoriánént, saját preferenciái szerint testreszabhatja.</span>
              <div className="cookie-detail">
                <div className="title">
                  <span>
                  Szükséges sütik
                  </span>
                  <MDBSwitch id="szukseges-cookie" checked={szuksegesSuti} onChange={() => setSzuksegesSuti(!szuksegesSuti)}/>
                </div>
                <div className="detail">
                  <span>
                  A weboldal fő funkciójához szükséges sütik elhelyezése, IP cím naplózás kizárólag a weboldal üzemeltetése céljából, a weboldal működőképessége, alapvető funkcióinak biztosítása, és a számítógépes rendszer biztonsága érdekében történik. Erre figyelemmel a sütik kezelésének jogalapja a GDPR 6. cikk (1) (f) pontja szerinti jogos érdek. A fentiekben írt sütik esetében a jogos érdek érvényesítése előnyt élvez az Ön személyes adatokhoz fűződő rendelkezési jogához képest, mivel enélkül nem megvalósítható a weboldal teljes körű működése és biztonsága. Ezen célból kezelt személyes adatok harmadik személynek csak a célhoz szükséges körben adhatóak át (például szerver üzemeltetőnek, rendőrségnek). Ezen céllal és jogalappal kezelt adatok egyéb céllal nem használhatóak fel.
                  </span>
                </div>
              </div>
              <div className="cookie-detail">
                <div className="title">
                  <span>
                  Statisztikai célú sütik
                  </span>
                  <MDBSwitch id="statisztika-cookie" checked={statisztikaSuti} onChange={() => setStatisztikaSuti(!statisztikaSuti)}/>
                </div>
                <div className="detail">
                  <span>
                  A statisztikai sütik lehetővé teszik a weboldalunk és hirdetési kampányaink teljesítményének mérését. Ezen sütiken keresztül megszerzett adatokat összesített formában dolgozzuk fel, olyan azonosítók használata nélkül, amelyek a weboldalunk meghatározott felhasználóira mutatnak. A statisztikai sütiket a weboldal látogatójának kifejezett hozzájárulásával kezeli (GDPR 6. cikk (1) bek. a) pontja) a Társaság.
                  </span>
                </div>
              </div>
              <div className="cookie-detail">
                <div className="title">
                  <span>
                  Reklám célú sütik
                  </span>
                  <MDBSwitch id="reklam-cookie" checked={reklamSuti} onChange={() => setReklamSuti(!reklamSuti)}/>
                </div>
                <div className="detail">
                  <span>
                  A reklám célú sütiknek az a célja, hogy a weboldalunkat összekapcsolják harmadik fél közösségi médiáival és hirdetési hálózatokkal, mind például a Facebook vagy Google Ads, illetve a hirdetéseket optimalizálja, marketing célokra. Ezzel a kapcsolattal releváns hirdetéseket tudjuk megjeleníteni Önnek akkor is, ha elhagyja a weboldalt. A reklámsütiket a weboldal látogatójának kifejezett hozzájárulásával kezeli (GDPR 6. cikk (1) bek. a) pontja) a Társaság.
                  </span>
                </div>
              </div>
              
              
              
              <div className="justify-content-center d-flex align-ites-center pt-3" >
                <button type="button" className="rergistration-button modal-button green" onClick={() => {cookies.set('CookieConsent',  {CookieConsent: true,
                                                                                                                                         szuksegesSuti: szuksegesSuti,
                                                                                                                                         statisztikaSuti: statisztikaSuti,
                                                                                                                                         reklamSuti: reklamSuti
                                                                                                                                        }, { path: '/'});
                                                                                                          context.onRenderTrue(); props.hideModal()}} >
                <span>Beállítások mentése</span>
                </button>
              </div>
              <div className="justify-content-center d-flex align-ites-center pt-2" >
                <button type="button" className="rergistration-button modal-button green" onClick={() => {cookies.set('CookieConsent',  true, { path: '/'}); context.onRenderTrue(); props.hideModal()}} >
                <span>Engedélyezze az összes cookie-t</span>
                </button>
              </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CookiesModal;