// REACT IMPORTS
import React, { useContext, useState } from "react";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

// MDBOOTSTRAP IMPORTS
import { MDBRow, MDBCol, MDBInput, MDBBtn } from "mdb-react-ui-kit";

// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';

//images
import cpeptide_footer from '../../../assets/media/icons/cpeptide-white.png'
import aszf from '../../../static/jogi/aszf.pdf'
import privacy from '../../../static/jogi/privacy.pdf'
const Footer = (props) => {
  const context = useContext(AppContext);

  const [email, setEmail] = useState("");

  const onGetAllNewsletter = () => {
    let self = this;
    if(email !== "") {
      context.interAxios.post('/newsletter', {email: email}, function(response) {
        setEmail("");
        context.openLoginModal("hirlevel");
      });
    }else {
      alert("Add meg az emailcímedet!")
      
    }
  }
  
  return (
    <footer className="cpeptide-footer" id="contact">
      <div className="d-flex align-items-center footer-top">
        <MDBRow style={{width: "100%"}} className="m-0">
            <div className="iratkozzfel-text">
              <span>Folyamatosan tájékoztatjuk Önt legfrissebb híreinkről.</span>
            </div>
        </MDBRow>
        <MDBRow style={{width: "100%"}} className="m-0 pb-5">
          <MDBCol xxl="3" xl="4" lg="4" md="12" sm="12" size="12" center className="px-0 footer-col-1">
              <img src={cpeptide_footer}
                alt=''
                loading='lazy'/>
          </MDBCol>
       
          <MDBCol xxl="6" xl="8"  lg="8" md="12" sm="12" size="12" center className="p-0 footer-col-2">
                <MDBInput value={email}
                          name='email'
                          type="email"
                          onChange={(e) => setEmail(e.target.value)}
                          label='E-mail'/>
                <MDBBtn type="btn" className="email-button" onClick={() => onGetAllNewsletter()}>
                  <span>
                    KÜLDÉS
                  </span>
                </MDBBtn>
           
          </MDBCol>
          <MDBCol xxl="3" xl="12"  lg="12" md="12" sm="12" size="12" center className="p-0 footer-col-3">
            <div className="copyright">
              <a href={aszf} target="_blank" rel="noopener noreferrer">
              <span>Felhasználási feltételek</span></a>
              <a href={privacy} target="_blank" rel="noopener noreferrer">
              <span>Adatvédelmi nyilatkozat</span></a>
            </div>
          </MDBCol>
        </MDBRow>
        <div className="copyright-logo">
          <span>Boda &copy; 2024</span>
        </div>
      </div>
    
      
    </footer>
  );
};

export default Footer;
