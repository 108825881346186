import React, { useContext, useState, useEffect  } from "react";
import AppContext from '../../../../AppContext';
import {
  MDBContainer,
  MDBNavbar,
  MDBCard,
  MDBCardBody,
  MDBNavbarBrand,
  MDBRipple,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBNavbarToggler,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
} from 'mdb-react-ui-kit';

function Navbar(props) {
  const context = useContext(AppContext);

  const [width, setWidth] = useState(window.innerWidth); 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1600 ? true : false); 


  const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
      setIsMobile(window.innerWidth < 1600 ? true : false);
  };

  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      };
  }, []);
    
  return (
        <MDBNavbar light className="admin-navbar">
              <MDBContainer fluid>
                <MDBNavbarNav className='ms-auto d-flex align-items-end'>
                  <MDBNavbarItem style={{ cursor: 'pointer' }}>
                  
                    <MDBDropdown>
                      <MDBDropdownToggle tag='a' className='hidden-arrow nav-link d-flex align-items-center'>
                        <MDBIcon far icon="user-circle"  size='2x'  color="white"/>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem link onClick={context.onLogout}>Kijelentkezés</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown> 
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBContainer>
        </MDBNavbar>
  );
}

export default Navbar;
