import React, { Component  } from "react";
import BasisModal from './FaqEditModal/BasisModal.js'


import {
  MDBAlert
} from 'mdb-react-ui-kit';


class FaqEditModal extends Component {

	constructor(props) {
    super(props);
    if(this.props.faq !== undefined){
      this.state = {
                 weight:this.props.oldPosition !== undefined ? this.props.oldPosition + 1 : "Default",
                 question:this.props.faq.question,
                 answer:this.props.faq.answer,
      };
    }else{
      this.state = {
                 weight: "Default",
                 question:"",
                 answer:"",
               };
    }

  }
  componentDidMount() {
    let serialNumberList = [];
    for(let i=0; i<this.props.itemsCount; i++){
      let number = i + 1;
      serialNumberList.push({text: number+"", value:i, defaultSelected: this.props.oldPosition === i});
    }

    this.setState({serialNumberList: serialNumberList});
    
  }

  internalHandleSave = () => {
    if(this.state.answer !== "" && this.state.question !== "" && this.state.weight !== "Default"){

      if(this.props.faq !== undefined){
        this.props.onUpdateFAQById(this.props.faq.id, this.state.question, this.state.answer, this.state.weight);
      }else{
        this.props.onCreateNewFAQ(this.state.question, this.state.answer, this.state.weight);
      }
      this.props.hideModal();

    }else{
      this.setState({alert: true, alertText:"Unvollständige Füllung! Bitte füllen Sie alle mit einem roten Stern gekennzeichneten Felder aus!"})
    }

  }

  onAlert = (alert, alertText) => {
    this.setState({alert: alert, alertText:alertText})
  }

  onWeightChange = e => {
    this.setState({weight: e.text})
  }


  onQuestionChange = (editorState) => {
    this.setState({question: editorState});
  }

  onAnswerChange = (editorState) => {
    this.setState({answer: editorState});
  }
  

  

  handleJustifyClick = (tab) => {
    if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
				}
  }

	render() {
    let self = this;
    return (
      <div className="modaltemplate-scss">
        <BasisModal {...this} {...this.state} {...this.props}/>
        <MDBAlert
          show={this.state.alert}
          color='primary'
          autohide
          position='top-center'
          offset={50}
          delay={3000}
          appendToBody
          onClosed={() => { self.setState({alert: false, alertText: ""})}}
        >
          {this.state.alertText}
        </MDBAlert>
      </div>
  );
  }
}
export default FaqEditModal;
