// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev-green.png'
import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/Group 3.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled-green.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty-green.png'
import search from '../../assets/media/icons/search.png'


// import Swiper and modules styles
import 'swiper/css';
import AppContext from '../../AppContext';
import { MDBContainer,
          MDBRow,
          MDBCol,
          MDBInput
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const CategoryDetailPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [open, setOpen] = useState(false);
  const [starttime, setStarttime] = useState(false);
  const [sliderImages, setSliderImages] = useState([]);
  const [videoList, setVideoList] = useState(location.state?.videoList !== undefined ? location.state?.videoList : {})
  const [categoryName, setCategoryName] = useState(location.state?.categoryName !== undefined ? location.state?.categoryName : "")
  const [searchText, setSearchText] = useState("");
  const [videocardHeight, setVideocardHeight] = useState(document.getElementById("thumbnail-image")?.clientWidth*9/16);


  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
    setVideocardHeight(document.getElementById("thumbnail-image")?.clientWidth*9/16)
 
  };


  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    setTimeout(() => {setStarttime(true) }, 100)
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);


  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 300)
  }, []);

  const onSearchText  = (event) => {
    setSearchText(event.target.value);
  };

  const truncateString = (str, num) => {
    
    if (str !== undefined && str.length <= num) {
      
        return str
      }
          return str.slice(0, num) + '...'
  }
 
  return (
    <>
        <div style={{minHeight: "63vh"}}>
          <button className="back-button-trainer" type="button" onClick={() => navigate('/videos')}>
            <img src={prev} alt="back"/><span>Back</span>
          </button>
          <section className="categorydetail-section">
           
            <MDBContainer fluid style={{maxWidth: "1670px"}}>
              <div className="trainer-name">
                <span >{categoryName}</span>
              </div>
            </MDBContainer>  
          </section>
          <section className="more-videos-section">
            <MDBContainer fluid style={{maxWidth: "1670px"}}>
            <div className="input-div">
                <MDBInput label={<><img src={search} alt="search"/><span>Keresés</span></>} id='form1' type='text' value={searchText} onChange={onSearchText} />
            </div>
            <MDBRow  className="m-0 pb-5">
              {videoList && categoryName && (<>
                {videoList.filter(k => (k.category === categoryName)).filter(k => k.name.toLowerCase().includes(searchText.toLowerCase())).map((k,n) => {
                  let array = k.name.split(" ")
                  return(
                  <MDBCol lg="3" md="3" size="6" key={n} className="pt-5 px-2">
                    <div className="videocard-more   d-none d-lg-block" key={n} id="touchsurface" style={{height: videocardHeight}}>
                      <div className="background-img">
                        <img src={k.pictureUrl} alt="thumbnail-image" id="thumbnail-image" className="thumbnail-image"/>
                        
                        <div className="description">
                            <span>{k.name}</span>
                        </div>
                        <div style={{position: "absolute", width: "100%", height: "32%",
                                      background:  "#ffffff",
                                      opacity: 0.86,
                                      bottom: "0px",
                                      borderRadius: "0 0 10px 10px"
                                    }}/>
                        <div className="description-hover">
                          
                          <button className="play-button" type="button" onClick={() => { navigate('/videodetail', { state: { video: k} })}}><img src={video_play} alt="player"/></button>

                          
                          
                          <div className="bookmark-div">
                            <div className="more-text">
                                <span>{k.name !== undefined ? truncateString(k.name, 40): ""}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="videocard-more-respo  d-block d-lg-none px-2" key={n} >
                      <div className="background-img" onClick={() => { navigate('/videodetail', { state: { video: k} })}}>
                        <img src={k.pictureUrl} alt="thumbnail-image"/>
                        <div style={{position: "absolute", width: "100%", height: "25%",
                                      background:  "#ffffff",
                                      opacity: 0.86,
                                      bottom: "0px",
                                      borderRadius: "0 0 10px 10px"
                                      }}/>
                        <div className="more-text">
                          <span>{k.name !== undefined ? truncateString(k.name, 20): ""}</span>
                        </div>
                      </div>
                    </div>
                  </MDBCol>
                )})}
              </>

              )}
                        
            </MDBRow>
            </MDBContainer>
          </section>
        </div>
    </>


  );
};

export default CategoryDetailPage;
