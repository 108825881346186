// react imports
import React, { useContext, useEffect, useState } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import prev from '../../assets/media/icons/prev-green.png'
import video_clock from '../../assets/media/icons/video-clock.png'
import video_play from '../../assets/media/icons/Group 3.png'
import bookmark_filled from '../../assets/media/icons/bookmark-filled-green.png'
import bookmark_empty from '../../assets/media/icons/bookmark-empty-green.png'
import like_filled from '../../assets/media/icons/like2.png'
import like_empty from '../../assets/media/icons/like.png'
import ReactPlayer from 'react-player'
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import AppContext from '../../AppContext';
import { MDBContainer
          } from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const VideosDetailPage = (props) => {

  const location = useLocation();
  const context = useContext(AppContext);

  // react router navigation
  const navigate = useNavigate();

  const [videoWidht, setVideoWidht] = useState(window.innerWidth < 1920 ? window.innerWidth : 1920);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
  const [isBookmarked, setIsBookmarked] = useState(location.state.video.bookmarked);
  const [isLiked, setIsLiked] = useState(location.state.video.isLiked);
  const [isVideoHover, setIsVideoHover] = useState(false);

  const handleWindowSizeChange = () => {
    setVideoWidht(window.innerWidth < 1920 ? window.innerWidth : 1920);
    setVideoHeight(window.innerHeight < 1920*0.5625 ? window.innerHeight-8 : 1920*0.5625);
 
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    };
    
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsVideoHover(true)}, 500)
  }, []);

const onPutVideoToBookmark = (id) => {
  context.interAxios.put('/bookmark/' + id, function(response) {
    setIsBookmarked(true)
  });
}

const onDeleteVideoFromBookmark = (id) => {
    context.interAxios.delete('/bookmark/' + id, function(response) {
      setIsBookmarked(false)
    });
}

const onPutVideoToLike = (id) => {
  context.interAxios.put('/like/' + id, function(response) {
    setIsLiked(true)
  });
}

const onDeleteVideoFromLike = (id) => {
    context.interAxios.delete('/like/' + id, function(response) {
      setIsLiked(false)
    });
}

  return (
    <MDBContainer fluid className="videodetail-container">
      <button className="black-button" type="button" onClick={() => {
                            if(location.state.categoryName !== undefined){
                              navigate('/videos', { state: { categoryName: location.state.categoryName  } })
                            }else {
                              navigate('/videos')
                            }}}><img src={prev} alt="back"/><span>Back</span></button>
      
      <section className="first-section" id="">
        
        <div className={isVideoHover ? "reactplayer-div hover-effect" : "reactplayer-div"}>
        <iframe src={location.state.video.fullUrl + "&controls=0&autoplay=1#t=" + parseInt(location.state.video.duration/180) + "m14s&end=" + parseInt(location.state.video.duration/3 + 120) }  width={videoHeight < videoWidht*0.5625 ? videoHeight/0.5625 :  videoWidht} height={videoHeight < videoWidht*0.5625 ? videoHeight : videoWidht*0.5625 +"px"} allowFullScreen="allowFullScreen" allow="autoplay"
              mozallowfullscreen="mozallowfullscreen" 
              msallowfullscreen="msallowfullscreen" 
              oallowfullscreen="oallowfullscreen" 
              webkitallowfullscreen="webkitallowfullscreen"></iframe>
        </div>
      
      </section>
      <section className="detail-section">
        <div className="video-title">
          <span className="title-text">
            {location.state.video.name}
          </span>

          <div className="like-div">
            {location.state.video.countAllLikes > 0 && (
              <span className="sum-like">{location.state.video.countAllLikes} embernek tetszik</span>
            )}
            <button className="like-button" type="button" onClick={isLiked ? 
                              () => onDeleteVideoFromLike(location.state.video.id) : () => onPutVideoToLike(location.state.video.id)}>
              <img src={isLiked ? like_filled : like_empty} alt="bookmark" />
              <span>Tetszik</span>
            </button>
          </div>
        </div>
        <div className="timer">
            <img src={video_clock} alt="timer"/>
            <span>{parseInt(location.state.video.duration/60) + "minutes"}</span>
        </div>
        <div className="play-button">
        <button type="button" onClick={() => { navigate('/videoplay', { state: { fullUrl: location.state.video.fullUrl, categoryName: undefined} })}}><img src={video_play} alt="player"/></button>
        </div>
        <div className="video-description">
          <span>{location.state.video.description}</span>
        </div>
       
        <div className="bookmark-div">
          <button className="bookmark-button" type="button" onClick={isBookmarked ? 
                            () => onDeleteVideoFromBookmark(location.state.video.id) : () => onPutVideoToBookmark(location.state.video.id)}>
            <img src={isBookmarked ? bookmark_filled : bookmark_empty} alt="bookmark" />
          </button>
          <span>{isBookmarked ? "Töröld a kedvencek listájáról" : "Add hozzá a kedvencekhez"}</span>
        </div>
      </section>

    </MDBContainer>


  );
};

export default VideosDetailPage;
