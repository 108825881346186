// react imports
import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import first_image from '../../assets/media/images/landing-first.png'
import left_image from '../../assets/media/images/landing-5.png'
import right_image from '../../assets/media/images/landing-6.png'
import brain from '../../assets/media/images/brain.png'
import video_play from '../../assets/media/icons/video-play.png'


// CUSTOM COMPONENTS
import AppContext from '../../AppContext';


import { MDBContainer
          } from 'mdb-react-ui-kit';
import Gyik from '../common/Gyik'
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const LandingPage = (props) => {

  const context = useContext(AppContext);
  
  // react router navigation
  const navigate = useNavigate();

  // STATES
  const [improveVideoWidth, setImproveVideoWidth] = useState(window.innerWidth < 1372 ? window.innerWidth : 1372);
  const [improveVideoHeight, setimproveVideoHeight] = useState(window.innerWidth < 1372 ? window.innerWidth*9/16 : 771.76);
  const [isPlayVideo, setIsPlayVideo] = useState(false);
  const [isPlayVideoTwo, setIsPlayVideoTwo] = useState(false);
  const [isPlayVideoThree, setIsPlayVideoThree] = useState(false);
  const [isPlayVideoFour, setIsPlayVideoFour] = useState(false);

  const [threeImagesHeight, setThreeImagesHeight] = useState(window.innerWidth > 1920 ?  2812 : (window.innerWidth/1920)*2812);
  const [firstImageWidth, setFirstImageWidth] = useState(window.innerWidth > 1920 ?  1143 : (window.innerWidth/1920)*1143);
  const [secondImageWidth, setSecondImageWidth] = useState(window.innerWidth > 1920 ?  1154.22 : (window.innerWidth/1920)*1154.22);
  const [thirdImageWidth, setThirdImageWidth] = useState(window.innerWidth > 1920 ?  1316.5 : (window.innerWidth/1920)*1316.5);

  const [firstTop, setFirstTop] = useState(window.innerWidth > 1920 ?  201.5 : (window.innerWidth/1920)*201.5);
  const [secondTop, setSecondTop] = useState(window.innerWidth > 1920 ?  1945 : (window.innerWidth/1920)*1945);
  const [isVideoHover, setIsVideoHover] = useState(false);
  const [isVideoHoverTwo, setIsVideoHoverTwo,] = useState(false);
  const [isVideoHoverThree, setIsVideoHoverThree] = useState(false);
  const [isVideoHoverFour, setIsVideoHoverFour] = useState(false);

  const [galleryHeight, setGalleryHeight] = useState(window.innerWidth > 1920 ?  2200 : (window.innerWidth/1920)*2200);
  const [siginHeight, setSigninHeight] = useState(window.innerWidth > 1920 ?  1179 : (window.innerWidth/1920)*1179);
   
  let [searchParams] = useSearchParams();

  const handleWindowSizeChange = () => {
    setGalleryHeight(window.innerWidth > 1920 ?  2200 : (window.innerWidth/1920)*2200)
    setSigninHeight(window.innerWidth > 1920 ?  1179 : (window.innerWidth/1920)*1179)
  };

  const scrollEventListener = () => {
    /*
    setTimeout(() => {
        if(window.location.pathname.split("/")[window.location.pathname.split("/").length-1] !== "videos"){
            if(window.scrollY < 100){
                context.onSetActiveItem("1");
                
            }
            if(window.scrollY > document.getElementById('videos').getBoundingClientRect().top + window.pageYOffset -400){
                //context.onSetActiveItem("2");
            }
            if(window.scrollY > document.getElementById('faq').getBoundingClientRect().top + window.pageYOffset -400){
                context.onSetActiveItem("3");
            }
            if (window.innerWidth > 991.98){
                if(window.scrollY > document.getElementById('contact').getBoundingClientRect().top + window.pageYOffset -1000){
                    context.onSetActiveItem("4");
                }
            }else{
                if(window.scrollY > document.getElementById('contact').getBoundingClientRect().top + window.pageYOffset -700){
                    context.onSetActiveItem("4");
                }
            }
            
           
        }
       
      }, 600)
    */
   
  }

  useEffect(() => {
    if (location.search.indexOf('emailVerified=') !== -1) {
      context.interAxios.get('/auth/emailvalidate', {params: {token: Object.fromEntries([...searchParams]).token}}, function(response) {
        context.openLoginModal("emailverified")
      }); 
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    document.addEventListener("scroll", scrollEventListener);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        document.removeEventListener("scroll", scrollEventListener);
    };
    
  }, []);
  
 
  return (
    <MDBContainer style={{maxWidth: "1920px", paddingLeft: window.innerWidth > 1920 ? 0 : 20, paddingRight: window.innerWidth > 1920 ? 0 : 20, marginTop: window.innerWidth > 992 ? 0 : 80}} className="landing-page">
      <div className="background-left" style={{marginLeft: window.innerWidth > 1920 ? -(window.innerWidth-1920)/2 : 0}}/>
      <div className="background-right"  style={{marginRight: window.innerWidth > 1920 ? -(window.innerWidth-1920)/2 : 0}}/>
      {!context.isLoggedIn() && (
      <section className="signin-signup-section" style={{height:siginHeight}}>
        <div className="left-col"
                style={{left: window.innerWidth > 1920 ? 150 : (window.innerWidth/1920)*150, top: window.innerWidth > 1920 ? 190 : (window.innerWidth/1920)*160}}>
          <div className="title" style={{marginLeft: window.innerWidth > 1920 ? -5 : -(window.innerWidth/1920)*5}}>
            <span className="first-text" style={{fontSize: window.innerWidth > 1920 ? 79.36 : (window.innerWidth/1920)*79.36}}>Fedezze fel a  <span className="second-text"> C-Peptide erejét</span></span>
          </div>
          <div className="description" style={{marginTop: window.innerWidth > 1920 ? 20 : (window.innerWidth/1920)*20}}>
            <span style={{fontSize: window.innerWidth > 1920 ? 21 : (window.innerWidth/1920)*21 +6}}>Csatlakozzon hozzánk, és fedezze fel, hogyan változtatta meg az életét számtalan embernek valami, amiröl eddig nem is igazán beszélhettünk..</span>
          </div>
          <div className="signin-signup-buttons" style={{marginTop:  window.innerWidth > 1920 ? 20 : (window.innerWidth/1920)*20+15}}>
            <button type="button" className="signin-button" onClick={() => context.openLoginModal("signin")} 
             style={{height:  window.innerWidth > 1920 ? 117 : (window.innerWidth/1920)*117,
             borderRadius:  window.innerWidth > 1920 ? 59 : (window.innerWidth/1920)*59 +6}}>
              <span style={{fontSize: window.innerWidth > 1920 ? 21 : (window.innerWidth/1920)*21 +8}}>BELÉPÉS</span>
            </button>
            <button type="button" className="signup-button" onClick={() => context.openLoginModal("signup")}
              style={{height:  window.innerWidth > 1920 ? 117 : (window.innerWidth/1920)*117,
              borderRadius:  window.innerWidth > 1920 ? 59 : (window.innerWidth/1920)*59 +8}}>
              <span style={{fontSize: window.innerWidth > 1920 ? 21 : (window.innerWidth/1920)*21 +6}}>REGISZTRÁCIÓ</span>
            </button>
          </div>

        </div>
        <div className="right-col" style={{top: window.innerWidth > 1920 ? -30 : 10}}>
          <img src={first_image} alt=""/>
        </div>
      </section>)}
      <section className="about-cpeptide-section" style={{marginLeft: window.innerWidth > 1920 ? -(window.innerWidth-1920)/2+100 : 0, paddingLeft: window.innerWidth > 1920 ?  (window.innerWidth-1920)/2-100 : 0, minHeight: (window.innerWidth/1920)*768, paddingTop: "30px" }}>
        <img src={brain} alt="brain" className="brain-image" style={{width: (window.innerWidth/1920)*1000}}/>
        <div className="about-text"  style={{width: window.innerWidth > 1920 ? 672 :(window.innerWidth/1920)*672}}>
          <div className="title">
            <span style={{fontSize: window.innerWidth > 1920 ? 79.36 : (window.innerWidth/1920)*79.36}}>Az élet fontos dolgai...</span>
          </div>
          <div className="description">
            <span style={{fontSize: window.innerWidth > 1920 ? 21 : (window.innerWidth/1920)*21 +8}}>
            A fontos dolgok az ember életében csak akkor tudatosulnak, ha vagy személyesen, vagy a közvetlen családi, baráti körében történnek visszafordíthatatlannak tűnő események.
            Ez történt velem is. Egy közeli barátomnak kellett megélnie, hogy cukorbetegsége következtében az orvosok már lemondtak bármilyen alternatív megoldásról és egyetlen megoldásként az amputációt látták. Szerintem senki nem szívesen fogadna el egy ilyen döntést és a világon bármit, de tényleg bármit képes megkeresni, ha esélyt lát… A barátom így talált rá egy lehetőségre, melyet azonnal ki is próbált. 
            Ma újra működik a vérkeringése, és amputáció nélkül élheti tovább életét.
            Természetes, hogy az én érdeklődési körömet is felkeltette ez a lehetőség és hosszas kutakodás után összegyűjtöttem minden olyan tapasztalatokat összefoglaló videót, amely elérhető volt.
            Kötelességemnek érzem, hogy megosszam mindenkivel, akinek nincs se ideje, se lehetőségé felkutatni a kapcsolódó tartalmakat.
            Ez az oldal erről szól! Regisztráció után elérhetővé válik számodra minden az adott témában fellelhető videó.

            </span>
          </div>
        </div>
      </section>
      <section className="gallery-section">
        <div className="gallery-title" style={{height:  window.innerWidth > 1920 ? 510.5 : (window.innerWidth/1920)*510.5}}>
          <span style={{maxWidth: window.innerWidth > 1920 ? 470 : (window.innerWidth/1920)*470, 
                        fontSize: window.innerWidth > 1920 ? 79.36 : (window.innerWidth/1920)*95}}>400 + elérhető videó</span>
        </div>
        <div className="gallery"  style={{height: galleryHeight, marginTop: window.innerWidth > 1920 ? -130 : -(window.innerWidth/1920)*130}}>
          <img src={left_image} alt="" className="left-image"/>
          <img src={right_image} alt="" className="right-image" id="right-image" style={{top: window.innerWidth > 1920 ? -200 : -(window.innerWidth/1920)*200}}/>
        </div>
      </section>
      <section className="faq-section" id="faq">
          
            <MDBContainer className="faq">
                <div className="faq-title pb-4">
                    <span style={{fontSize: window.innerWidth/1920 > 1 ? 60 : window.innerWidth/1920 > 0.5 ? (window.innerWidth/1920)*60 : (window.innerWidth/1920)*90}}>
                    Gyakori kérdések 
                    </span>
                </div>
                <Gyik/>
            </MDBContainer>
       
        </section>
    </MDBContainer>
  );
};

export default LandingPage;
