// REACT IMPORTS
import React, { useContext, Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';
import axios from "axios";
import { backendUrl } from '../../env';
import { FacebookAppId, AppleLogin } from '../../data/loginkeys';

import loginAppleIcon from "../../assets/media/icons/apple-icon.svg";
import loginFacebookIcon from "../../assets/media/icons/facebook-icon.svg";
import loginGoogleIcon from "../../assets/media/icons/google-icon.svg";

import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBIcon,
  MDBInputGroup,
  MDBLoadingManagement
} from 'mdb-react-ui-kit';

// STYLE IMPORTS
import "react-toggle/style.css";


class SignInForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    password: ""      
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      let customer = {
                    email: this.state.email,
                    password: this.state.password    
      };
      this.props.loginEvent(this.state.email, this.state.password)
      //this.props.context.onSignIn()
      //this.props.hideModal();
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

  
  render() {
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="signin-form" autoComplete="off">
                <div className="white-panel">
                  {/** EMAIL **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="firstName">E-mail cím</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordRef.current.focus()
                          }
                        }}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Jelszó</label><br/>
                      <MDBInputGroup
                        className="jc-password"
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.buttonRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>


                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-flex align-items-center justify-content-center p-0" >
                      <button type="button" className="rergistration-button modal-button text-button" 
                        onClick={() => {this.props.context.closeLoginModal(); this.props.context.openLoginModal("forgotpassword");}}>
                      <span>Elfelejtett jelszó</span>
                      </button>
                    </MDBCol>
                  </MDBRow>
                  {this.props.context.getState()?.isLoading && (
                    <div ref={this.props.colorsRef} style={{ height: "70px", width: "100%", zIndex: "1029", marginTop: "-50px", marginBottom: "20px" }}>
                      <MDBLoadingManagement color='success' parentRef={this.props.colorsRef} backdropColor='blue' backdropOpacity={0.2} loadingText="Betöltés..." />
                    </div>
                  )}
                  <MDBRow className=" m-0">
                    <MDBCol lg='6' size="12"  className="d-flex align-items-center justify-content-center px-0 pb-4" >
                          
                          
                            <button type="submit" className="rergistration-button modal-button green" ref={this.props.buttonRef}>
                            <span>Belépés</span>
                            </button>
                            
                          
                          
                    </MDBCol>
                    <MDBCol lg='6' size="12"  className="d-flex align-items-center justify-content-center px-0 pb-4" >
                          
                          
                           
                            <button type="button" className="rergistration-button modal-button  black" 
                            onClick={() => {this.props.context.closeLoginModal(); this.props.context.openLoginModal("signup");}}>
                            <span>Regisztráció</span>
                            </button>
                          
                          
                    </MDBCol>
                  </MDBRow>

                 

                </div>
              </form>
              <MDBRow className="pt-4 m-0">
                    <div style={{width: "100%"}}>
                      <FacebookLogin
                        appId= {FacebookAppId.appId}
                        style={{
                          backgroundColor: 'transparent',
                          color: '#314A2A',
                          textAlign: 'center', 
                          fontSize: '18px',
                          fontStyle: 'normal',
                          //fontWeight: 800,
                          lineHeight: '26px', 
                          padding: "10px 20px 10px 25px",
                          border: '2px solid #314A2A',
                          borderRadius: "25px",
                          height: "50px",
                          minWidth: '268px',
                          width: "100%"
                        }}
                        children={<div><img src={loginFacebookIcon} alt="Facebook Icon" height='25' className="me-2"/>
                        FOLYTATÁS FACEBOOK FIÓKKAL</div>} 
                        onSuccess={(response) => {
                          this.props.context.setIsLoading(true);
                          let self = this;
                          this.props.context.interAxios.post('/auth/facebook/login', {token: response.accessToken}, function(response) {
                            self.props.context.onSignIn(response.data);
                            self.props.context.setIsLoading(false)
                            self.props.context.closeLoginModal();
                          }, function(error) {
                            console.log(error.response.data.message)
                            if(error.response.data.message === "user not found"){
                              self.props.context.closeLoginModal();
                              self.props.context.setIsLoading(false)
                              setTimeout(() => {self.props.context.openSocialLoginModal("socialsignup", response.accessToken, "", "facebook") }, 300)
                              
                            }
                          });
                        }}
                        onFail={(error) => {
                            console.log('Login Failed!', error);
                        }}
                        onProfileSuccess={(response) => {
                            console.log(response);
                        }}
                      />
                    </div>
                  </MDBRow>
                  <MDBRow className="pt-3 m-0">
                    <div style={{width: "100%"}}>
                      <button className="social-google" onClick={() => {this.props.googleLogin(); this.props.context.setIsLoading(true)}}>
                        <img src={loginGoogleIcon} alt="Google Icon" height='25'/>
                        <span>BEJELENTKEZÉS GOOGLE FIÓKKAL</span>
                      </button> 
                    </div>
                  </MDBRow>

                  <MDBRow className="pb-3 pt-3 m-0">
                      <div style={{width: "100%"}}>
                        <AppleSignin
                          authOptions={{
                            clientId: AppleLogin.clientId,
                            scope: AppleLogin.scope,
                            redirectURI: AppleLogin.redirectURI,
                            state: AppleLogin.state,
                            nonce: AppleLogin.nonce,
                            usePopup: AppleLogin.usePopup,
                          }}
                          uiType="dark"
                          className="apple-auth-btn"
                          noDefaultStyle={false}
                          buttonExtraChildren="Continue with Apple"
                          onSuccess={(response) => {
                            let self = this;
                            self.props.context.setIsLoading(true)
                            axios.post(backendUrl() +'/auth/apple/login', { 'token': response.authorization.id_token, 'authorizationCode': response.authorization.code }, { headers: { 'Content-type': 'application/json', 'Accept': 'application/json'}}).then(function(response) {
                              self.props.context.onSignIn(response.data);
                              self.props.context.setIsLoading(false)
                              self.props.context.closeLoginModal();
                            }, function(error) {
                              console.log(error)
                              if(error.response.data.message === "user not found"){
                                self.props.context.openSocialLoginModal("socialsignup", response.authorization.id_token, response.authorization.code, "apple", response.user?.name?.lastName, response.user?.name?.firstName)
                                self.props.context.setIsLoading(false)
                              }
                            })
                          }}
                          onError={(error) => console.error(error)}
                          skipScript={false}
                          render={(props) => <button className="social-apple" {...props}> <img src={loginAppleIcon} alt="Apple Icon" height='25'/>
                          <span>BEJELENTKEZÉS APPLE FIÓKKAL</span></button>}
                        />
                      </div>
                  
                  </MDBRow>
      </>
    );
  }
};

function SignInFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const passwordRef = useRef();
  const buttonRef = useRef();
  const colorsRef = useRef(null);
  const [hidePassword, setHidePassword] = useState(true);

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      console.log(tokenResponse.access_token);
      context.interAxios.post('/auth/google/login', {token: tokenResponse.access_token}, function(response) {
        context.onSignIn(response.data);
        context.setIsLoading(false)
        context.closeLoginModal();
      }, function(error) {
        if(error.response?.data.message === "user not found"){
          context.openSocialLoginModal("socialsignup", tokenResponse.access_token, "", "google")
          context.setIsLoading(false)
        }
      });
    },
    onError: (error) => alert('Login Failed:', error)
  });



  return <SignInForm searchParams={searchParams} 
                     navigate={navigate} 
                     hideModal={props.hideModal} 
                     context={context} 
                     loginEvent={props.loginEvent} 
                     passwordRef={passwordRef}
                     buttonRef={buttonRef}
                     hidePassword={hidePassword}
                     setHidePassword={setHidePassword}
                     googleLogin={googleLogin}
                     colorsRef={colorsRef}
                     />;
}

export default SignInFormFunc;
