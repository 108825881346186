// REACT IMPORTS
import React, { useContext, Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import AppContext from '../../AppContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
   MDBCol, 
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";

// OTHER IMPORTS
import PasswordInput from './PasswordInput';

class ProfileEditForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    firstName: "",
                    lastName: "",
                    password: "",
                    newPassword: "",
                    confirmPassword: "",
                    isPasswordchange: false,      
      };
    };

     componentDidMount() {
      this.getClientMe();
     }

     getClientMe = () => {
      let self = this;
      this.props.context.interAxios.get('/client/me', function(response) {
        console.log(response.data)
        self.setState({ email: response.data.email,
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                       
                        delete: response.data.delete
                      });
                        
      });
    }

    onNewCard = () => {
      let self = this;
      if(this.state.paymentService === "SIMPLEPAY"){
        this.props.context.interAxios.post('/client/me/newcard', undefined, function(response) {
          self.props.context.onPaymentProcess(response.data.paymentUrl, "SIMPLEPAY");
          self.props.context.openLoginModal("succesRegistration");
          let url = response.data.paymentUrl
          setTimeout(() => {
            //self.props.context.closeLoginModal();
            self.props.context.onLogout()
            window.open(url, '_self').focus();
            
          }, 2000);
        });
      }
      if(this.state.paymentService === "PAYPAL"){

        let url = "https://www.paypal.com/signin"
        this.props.context.onPaymentProcess(url, "PAYPAL");
        this.props.context.openLoginModal("succesRegistration");
        setTimeout(() => {
          //self.props.context.closeLoginModal();
          //context.onLogout()
          window.open(url, '_blank').focus();
          
        }, 2000);
      }
    }

   

    onCustomer = (e) => {
      e.preventDefault();
      let self = this;
      this.props.context.interAxios.put('/client/me', {
        email: this.state.email,
        lastName: this.state.lastName,
        firstName: this.state.firstName
        
      }, function(response) {
       self.getClientMe();
      });
      this.props.hideModal();
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    onPasswordCahngeShow = () => {
      this.setState({isPasswordchange : true})
    }
    onPasswordCahngeHide = () => {
      this.setState({isPasswordchange : false})
    }

    onNewPassword = () =>  {
      let self = this;
      if(this.state.newPassword === this.state.confirmPassword) {
        this.props.context.interAxios.put('/auth/password', {
          oldPassword: this.state.password,
          password: this.state.newPassword
        }, function(response) {
          self.props.loginEvent(self.state.email, self.state.newPassword)
          self.setState({newPassword: "", password: "", confirmPassword: ""});
          self.onPasswordCahngeHide();
        });
      }else {
        alert('Ellenőrizd a megadott jelszavakat!')
      }
     
    }

    undeleteProfile = () => {
      let self = this;
      this.props.context.interAxios.post('/client/me/undelete', undefined, function(response) {
        console.log("itt vagyok")
        self.getClientMe();
      });
    
    }


  
  render() {
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="signin-form" autoComplete="off">
                <div className="white-panel">
                  <MDBRow className="py-2 m-0">
                    <MDBCol md='12'>
                      <label htmlFor="lastName">Vezetéknév</label><br/>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.lastName}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.firstnameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='12'>
                      <label htmlFor="firstName">Keresztnév</label><br/>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.firstName}
                        ref={this.props.firstnameRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='12'>
                      <label htmlFor="email">E-mail cím</label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.nameRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                    <MDBCol md='12'  className="d-flex align-items-center justify-content-center py-4">    
                      <div>
                        <button type="button" className="rergistration-button modal-button green profile-edit"  onClick={!this.state.isPasswordchange ? this.onPasswordCahngeShow : this.onPasswordCahngeHide}>
                          <span>Jelszó csere</span>
                        </button>
                      </div>
                    </MDBCol>
                    {this.state.isPasswordchange && (
                      <>
                      <MDBCol md='12'>
                        <label htmlFor="password">Régi jelszó</label>
                        <PasswordInput
                          id='password'
                          name='password'
                          type='password'
                          onChange={this.changeHandler}
                          value={this.state.password}
                          required
                        />
                      </MDBCol>
                      <MDBCol md='12'>
                        <label htmlFor="newPassword">Új jeszó</label>
                        <PasswordInput
                          id='newPassword'
                          name='newPassword'
                          type='password'
                          onChange={this.changeHandler}
                          value={this.state.newPassword}
                          required
                        />
                      </MDBCol>
                      <MDBCol md='12'>
                        <label htmlFor="confirmPassword">Új jeszó mégegyszer</label>
                        <PasswordInput
                          id='confirmPassword'
                          name='confirmPassword'
                          type='password'
                          onChange={this.changeHandler}
                          value={this.state.confirmPassword}
                          required
                        />
                      </MDBCol>
                      <MDBCol md='12'  className="d-flex align-items-center justify-content-center py-4">    
                        <div>
                          <button type="button" className="rergistration-button modal-button green profile-edit"  onClick={this.onNewPassword}>
                            <span>Új jelszó mentése</span>
                          </button>
                        </div>
                      </MDBCol>
                      </>
                    )}
                  </MDBRow>
                  <MDBRow className="pb-2 m-0">
                    <MDBCol lg='6' size="12"  className="d-flex align-items-center justify-content-center px-0 pb-4">    
                      <div>
                        <button ref={this.props.buttonRef} type="submit" className="rergistration-button modal-button green profile-edit" >
                        <span>Mentés</span>
                        </button>
                      </div>
                    </MDBCol>
                    <MDBCol lg='6' size="12"  className="d-flex align-items-center justify-content-center px-0 pb-4">    
                      <div>
                        <button type="button" onClick={() => {this.props.context.closeLoginModal(); setTimeout(() => this.props.context.openLoginModal("deleteProfile"), 500); }} className="rergistration-button modal-button black profile-edit" >
                          <span>Profilom törlése</span>
                        </button>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </form>
      </>
    );
  }
};

function ProfileEditFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const navigate = useNavigate();
  const firstnameRef = useRef();
  const emailRef = useRef();
  const passwordfirstRef = useRef();
  const passwordtwoRef = useRef();
  const nameRef = useRef();
  const addressRef = useRef();
  const postcodeRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const buttonRef = useRef();
  const context = useContext(AppContext);
  return <ProfileEditForm searchParams={searchParams} 
                          navigate={navigate} 
                          hideModal={props.hideModal}
                          loginEvent={props.loginEvent}
                          firstnameRef={firstnameRef}
                          emailRef={emailRef}
                          passwordfirstRef={passwordfirstRef}
                          passwordtwoRef={passwordtwoRef}
                          nameRef={nameRef}
                          addressRef={addressRef}
                          postcodeRef={postcodeRef}
                          cityRef={cityRef}
                          countryRef={countryRef}
                          buttonRef={buttonRef}
                          hidePassword={hidePassword}
                          setHidePassword={setHidePassword}
                          hidePasswordtwo={hidePasswordtwo}
                          setHidePasswordtwo={setHidePasswordtwo}
                          context={context}
                          />;
}

export default ProfileEditFormFunc;
