import React, { Component, useContext, useState, useRef  } from "react";
import { Navigate, Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from '../../../../AppContext';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import GenericModal from './GenericModal.js';
import LiveEventModal from './LiveEventModal.js';


import {
  MDBContainer,
  MDBNavbar,
  MDBCard,
  MDBCardBody,
  MDBNavbarBrand,
  MDBRipple,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBAlert
} from 'mdb-react-ui-kit';

const localizer = momentLocalizer(moment)

class LiveCalendarPage extends Component {

  constructor(props) {
  	super(props);
    this.state = {};
    this.getMyEvents();
	}

  // SalonModal letöltő és event handlerek
	getMyEvents = () => {
		let self = this;
		this.props.context.interAxios.get('/admin/live', function(response) {
			let events = response.data.map((event) => {
				event.start = moment(event.start).toDate();
				event.end = moment(event.start).add(2, 'hours').toDate();
				return event;
			});
			self.setState({events: events});
		});
	}

  onSelectSlot = (slot) => {
    //console.log("select slot")
    //console.log(slot)
    if (!this.canCreateNewLiveEventBasedOnDateTime(slot)) {
      return;
    }
    this.setState(
      {
        modalConfig: {
          modalTitle: 'Live hozzáadása',
          error: undefined,
          titleEditable: true,
          startTimeEditable: true,
          summaryEditable: true,
          okButton: {
            show: true,
            text: 'Hozzáadás',
            color: 'green'
          }
        },
        modalData: {
          ...slot
        },
        modalSave: this.handleNewSalonEvent
      });
    this.openModal();
  }

  // Mikorra lehet eseményt készíteni?
	canCreateNewLiveEventBasedOnDateTime = (slot) => {
		let start = moment(slot.start);
		let now = moment();
		return moment(start).isAfter(now, 'days');
	}


  onSelectEvent = (event) => {
    //console.log(event);
    this.setState(
      {
        modalConfig: {
          modalTitle: 'Live módosítása',
          error: undefined,
          titleEditable: true,
          startTimeEditable: true,
          summaryEditable: true,
          okButton: {
            show: true,
          },
          thirdButton: {
            show: true,
            text: 'Törlés',
            color: 'red',
            handleClick: this.handleCancelSalonEvent
          }
        },
        modalData: event,
        modalSave: this.handleModifySalonEvent
      });
      this.openModal();
  }

  onDoubleClick = (event) => {}

  freeUsable = (start) => {
    return true;
  }

  // LiveEventModal
	openModal = () => {
    //console.log('openModal')
		this.setState({modalShow: true})
	}

	closeModal = (reactEvent) => {
		this.setState({modalShow: false})
	}

  handleNewSalonEvent = (data) => {
		//console.log("Creating new event " + JSON.stringify(data));
		let self = this;
    this.props.context.interAxios.post('/admin/live', data, function(response) {
      self.getMyEvents();
      self.closeModal();
    }, function(error) {
			//console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

  handleModifySalonEvent = (data) => {
		//console.log("Modify event " + JSON.stringify(data));
		let self = this;
		this.props.context.interAxios.put('/admin/live/' + data.id, data, function(response) {
			self.getMyEvents();
			self.closeModal();
		}, function(error) {
			//console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

	handleCancelSalonEvent = (data) => {
		//console.log("Cancel event: " + JSON.stringify(data));
		let self = this;
		this.props.context.interAxios.delete('/admin/live/' + data.id, {}, function(response) {
			self.getMyEvents();
			self.closeModal();
		}, function(error) {
			//console.log("errorFunction is running: " + error.response?.data)
			self.handleBackendError(error.response?.data);
		});
	}

  handleBackendError = (error) => {
		let modalConfig = this.state.modalConfig;
		modalConfig.error = error;
		this.setState({modalConfig: modalConfig});
	}

  render(){
    //console.log(this.state);
    return (
      <>
        <MDBContainer className='pt-4 pt-lg-5'>
          <MDBCard style={{ width: 'calc(100%-320px)', padding: "15px", marginLeft: "290px"}}>
            <Calendar
              localizer={localizer}
              events={this.state.events}
			        selectable={true}
			        onSelectSlot={this.onSelectSlot}
			        onSelectEvent={this.onSelectEvent}
			        onDoubleClickEvent={this.onDoubleClick}
              startAccessor="start"
              endAccessor="end"
              titleAccessor="name"
              views={['month', 'week', 'day']}
              style={{ height: 600, width: '100%' }}
              messages={{
                date: 'Dátum',
                 time: 'Idő',
                 event: 'Live',
                allDay: 'Egész nap',
                week: 'Hét',
                work_week: 'Munkanapok',
                day: 'Nap',
                month: 'Hónap',
                previous: 'Előző',
                next: 'Következő',
                yesterday: 'Tegnap',
                tomorrow: 'Holnap',
                today: 'Ma',
                agenda: 'Napirend',
                noEventsInRange: 'Nincs esemény.',
                showMore: function showMore(total) {
                  return '+' + total + " további";
                }
              }}
            />
          </MDBCard>
          
          <div className="p-5" style={{height: "50px"}}/>
          <LiveEventModal
							 	  data={this.state.modalData}
									config={this.state.modalConfig}
									show={this.state.modalShow}
									handleSave={this.state.modalSave}
									handleClose={this.closeModal}
							 />
               <GenericModal isOpen={true}/>
        </MDBContainer>
      </>
    );
  }

}

export default function LiveCalendarPageFunc(props) {
    const context = useContext(AppContext);
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const nodeRef = useRef(null)
    return <LiveCalendarPage context={context} searchParams={searchParams} navigate={navigate} nodeRef={nodeRef} />;
}
