// REACT IMPORTS
import React, { useContext, Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppContext from "../../AppContext";
import Cookies from "universal-cookie";
import axios from "axios";

const cookies = new Cookies();
// MDBOOTSTRAP IMPORTS
import { MDBRow, MDBCol, MDBIcon, MDBInputGroup } from "mdb-react-ui-kit";

// STYLE IMPORTS
import "react-toggle/style.css";

const { REACT_APP_BACKEND_URL } = process.env;

class ResetpasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
    };
  }

  onCustomer = (e) => {
    e.preventDefault();
    let self = this;

    if (this.state.password === this.state.confirmPassword) {
      axios
        .put(
          REACT_APP_BACKEND_URL + "/auth/password",
          {
            password: this.state.password,
          },
          {
            headers: {
              Authorization:
                "Bearer " +
                Object.fromEntries([...this.props.searchParams]).authToken,
            },
          },
        )
        .then(function (response) {
          self.props.context.closeLoginModal();
          setTimeout(() => {
            self.props.context.openLoginModal("signin");
          }, 500);
        });
    } else {
      alert("Ellenőrizd a megadott jelszavakat!");
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <>
        <form
          onSubmit={(e) => this.onCustomer(e)}
          className="profile-form"
          autoComplete="off"
        >
          <div className="white-panel">
            {/** FIRST NAME **/}

            {/** PASSWORDs **/}
            <MDBRow className="pb-2 m-0">
              <MDBCol md="12">
                <label htmlFor="password">Jelszó*</label>
                <MDBInputGroup
                  className="jc-password"
                  noWrap
                  textAfter={
                    <MDBIcon
                      className={
                        "fa-regular" +
                        (this.props.hidePassword ? " fa-eye" : " fa-eye-slash")
                      }
                      onClick={() =>
                        this.props.setHidePassword(!this.props.hidePassword)
                      }
                    ></MDBIcon>
                  }
                >
                  <input
                    type={this.props.hidePassword ? "password" : "text"}
                    value={this.state.password}
                    onChange={this.changeHandler}
                    id="password"
                    name="password"
                    onKeyDown={(e) => {
                      if (e.key == "Tab") {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.passwordRef.current.focus();
                      }
                    }}
                    required
                  />
                </MDBInputGroup>
              </MDBCol>
              <MDBCol md="12">
                <label htmlFor="confirmPassword">Jelszó mégegyszer*</label>
                <MDBInputGroup
                  className="jc-password"
                  noWrap
                  textAfter={
                    <MDBIcon
                      className={
                        "fa-regular" +
                        (this.props.hidePasswordtwo
                          ? " fa-eye"
                          : " fa-eye-slash")
                      }
                      onClick={() =>
                        this.props.setHidePasswordtwo(
                          !this.props.hidePasswordtwo,
                        )
                      }
                    ></MDBIcon>
                  }
                >
                  <input
                    type={this.props.hidePasswordtwo ? "password" : "text"}
                    value={this.state.confirmPassword}
                    onChange={this.changeHandler}
                    ref={this.props.passwordRef}
                    id="confirmPassword"
                    name="confirmPassword"
                    onKeyDown={(e) => {
                      if (e.key == "Tab") {
                        e.preventDefault();
                        e.stopPropagation();
                        this.props.buttonRef.current.focus();
                      }
                    }}
                    required
                  />
                </MDBInputGroup>
              </MDBCol>
            </MDBRow>

            <MDBRow className="pb-2 m-0">
              <MDBCol
                md="12"
                className="d-grid align-items-center justify-content-start p-0"
              >
                <div>
                  <button
                    type="submit"
                    className="rergistration-button modal-button green"
                    ref={this.props.buttonRef}
                  >
                    <span>Passwort reset</span>
                  </button>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        </form>
      </>
    );
  }
}

function ResetpasswordFormFunc(props) {
  const context = useContext(AppContext);
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const navigate = useNavigate();
  const passwordRef = useRef();
  const buttonRef = useRef();
  return (
    <ResetpasswordForm
      searchParams={searchParams}
      navigate={navigate}
      hideModal={props.hideModal}
      context={context}
      passwordRef={passwordRef}
      buttonRef={buttonRef}
      hidePassword={hidePassword}
      setHidePassword={setHidePassword}
      hidePasswordtwo={hidePasswordtwo}
      setHidePasswordtwo={setHidePasswordtwo}
    />
  );
}

export default ResetpasswordFormFunc;
