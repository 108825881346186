import { envDefaultString } from '../env.js';

//PROD
/*
export const FirebaseConfig = {
    apiKey: "AIzaSyCMi9aM8e_T-PZWTvnBoAvLlg7CufqS0QQ",
    authDomain: "jc-world-765f1.firebaseapp.com",
    projectId: "jc-world-765f1",
    storageBucket: "jc-world-765f1.appspot.com",
    messagingSenderId: "3220185788",
    appId: "1:3220185788:web:a9a331076a5b50bfafa6b9",
    measurementId: "G-2GGWXNN0KR"
  };
  

export const FirebaseVapidKey = {
    vapidKey: 'BBWF_X3aW_DeH6XUX29TeCdE8ZqhxhyNhV0HxDYdyR_RsPlV9AK9a5tqZai2kYV6ZYy_v6ji9EEgMUDYRqCin7Y'
};

export const StripeKey = {
    stripeKey: 'pk_live_51Mg2DhLFLYBucoyaUP5t4utmRBA24shQMdUxooDtBRQuFCNIrUGhk6qjOdGoduXhHiy5FxKnKV3uP3JjcMtbUqV700nTgMESAY'
};

export const FacebookAppId = {
    appId: '867735527616739'
};

export const GoogleOAuthId = {
    clientId: '3220185788-b3mhf4vvukmo11tflpn43umfanm3dq5l.apps.googleusercontent.com'
};

export const AppleLogin = {
    clientId: 'com.jcworld.web.prod',
    scope: 'email name',
//    redirectURI: 'https://cd72-2001-4c4c-1d21-f200-432-9db0-4b6a-4b9.ngrok-free.app',
   // redirectURI: 'https://prod.jandc-world.ch',
    redirectURI: 'https://jandc-world.ch',
    state: 'state',
    nonce: 'nonce',
      usePopup: true,
};

export const StripeReturnUrl = {
   //url: 'https://prod.jandc-world.ch/checkout/confirmation/'
   url: 'https://jandc-world.ch/checkout/confirmation/'
};

*/




// Stage
/*
  export const FirebaseConfig = {
      apiKey: "AIzaSyCMi9aM8e_T-PZWTvnBoAvLlg7CufqS0QQ",
      authDomain: "jc-world-765f1.firebaseapp.com",
      projectId: "jc-world-765f1",
      storageBucket: "jc-world-765f1.appspot.com",
      messagingSenderId: "3220185788",
      appId: "1:3220185788:web:a9a331076a5b50bfafa6b9",
      measurementId: "G-2GGWXNN0KR"
  };

  export const FirebaseVapidKey = {
      vapidKey: 'BBWF_X3aW_DeH6XUX29TeCdE8ZqhxhyNhV0HxDYdyR_RsPlV9AK9a5tqZai2kYV6ZYy_v6ji9EEgMUDYRqCin7Y'
  };

  export const StripeKey = {
      stripeKey: 'pk_test_51Mg2DhLFLYBucoyafNBZbeb0Uk5IYXYfFXg2hP1lkTgl7jGvvtkGEBT8NrqUMm6Hm9o5T7KoYUTkKUFNyEpHmFK9000kV6GUM7'
  };

  export const FacebookAppId = {
      appId: '867735527616739'
  };

  export const GoogleOAuthId = {
      clientId: '3220185788-vhjkek1gvdjnnr0svh7t6a4kji4hdikk.apps.googleusercontent.com'
  };

  export const AppleLogin = {
      clientId: 'com.jcworld.web.stage',
      scope: 'email name',
  //    redirectURI: 'https://cd72-2001-4c4c-1d21-f200-432-9db0-4b6a-4b9.ngrok-free.app',
      redirectURI: 'https://stage.jandc-world.ch',
      state: 'state',
      nonce: 'nonce',
        usePopup: true,
  };

 export const StripeReturnUrl = {
     url: 'https://stage.jandc-world.ch/checkout/confirmation/'
 };

*/


//Stage

 export const FacebookAppId = {
     appId: envDefaultString(process.env.REACT_APP_FACEBOOK_APP_ID, '1228341421474316')
 };

 export const GoogleOAuthId = {
     clientId: envDefaultString(process.env.REACT_APP_GOOGLE_OAUTH_ID, '892676418792-gccu81nbk96r5ucjbc4ek7cjepke88u2.apps.googleusercontent.com')
 };

 export const AppleLogin = {
     clientId: envDefaultString(process.env.REACT_APP_APPLE_CLIENT_ID, 'com.proinsulin-cpeptide.signin.web'),
     scope: 'email name',
     //redirectURI: 'https://3b69-2001-4c4c-1d21-f200-9081-2dde-6fda-4931.ngrok-free.app',
     redirectURI: envDefaultString(process.env.REACT_APP_APPLE_REDIRECT_URI, 'https://proinsulin-cpeptide.com'),
     state: 'state',
     nonce: 'nonce',
     usePopup: true,
 };






